import { logEvent } from '@config/azureInsights';
import i18n, { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import { IconFiles } from '@fortum/elemental-ui';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LinksDirection, Tile, TileLink } from './Tile';
import { User } from '@models/user';
import { compact } from 'lodash';
import { isCostReportToggleOn, isDashboardReportToggleOn, isWasteReportToggleOn } from '@utils/featureToggles';

interface ReportsTileProps {
  linksDirection?: LinksDirection;
  user: User;
}

export const ReportsTile: FC<ReportsTileProps> = ({ user, linksDirection = 'horizontal' }) => {
  const { t } = useTranslation<Namespace[]>(['reports', 'home']);

  const links = useMemo<TileLink[]>(
    () =>
      compact([
        isDashboardReportToggleOn(user) && {
          id: 'dashboard-forward-link',
          description: t('reports:dashboard.navigation'),
          path: paths.dashboardReport,
          onClick: () => logEvent('homeQuickAccessReportsDashboardClicked', null),
        },
        isWasteReportToggleOn(user) && {
          id: 'waste-details-report-forward-link',
          description: t('reports:wasteQty.navigation'),
          path: paths.wasteDetailsReport,
          onClick: () => logEvent('homeQuickAccessReportsWasteDetailsClicked', null),
        },
        isCostReportToggleOn(user) && {
          id: 'cost-details-report-forward-link',
          description: t('reports:cost.navigation'),
          path: paths.costDetailsReport,
          onClick: () => logEvent('homeQuickAccessReportsCostDetailsClicked', null),
        },
      ]),
    [i18n.language],
  );

  return (
    <Tile
      linksDirection={linksDirection}
      icon={<IconFiles />}
      header={t('home:quickAccess.reports.header')}
      description={t('home:quickAccess.reports.desc')}
      links={links}
    />
  );
};
