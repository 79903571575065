import { JSONSchemaType } from 'ajv';
import { QlikConnectionConfig, Selection } from '@components/qlik';

export const defaultSelectionsSchema: JSONSchemaType<Selection> = {
  $id: 'default-selections-schema',
  type: 'object',
  properties: { fieldName: { type: 'string' }, values: { type: 'array', items: { type: 'string' } } },
  required: ['fieldName', 'values'],
};

export const connectionSchema: JSONSchemaType<QlikConnectionConfig> = {
  $id: 'connection-schema',
  type: 'object',
  properties: {
    host: { type: 'string' },
    isSecure: { type: 'boolean' },
    port: { type: 'integer', nullable: true },
    prefix: { type: 'string' },
    qsServerType: { type: 'string', enum: ['onPrem'] },
  },
  required: ['host', 'isSecure', 'qsServerType', 'prefix'],
};
