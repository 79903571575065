import Ajv, { JSONSchemaType } from 'ajv';
import { CostReportAppConfig, CostReportConfig, CostReportObjects } from './types';
import { defaultSelectionsSchema } from '../schemas';

const objectsSchema: JSONSchemaType<CostReportObjects> = {
  $id: 'objects-schema',
  type: 'object',
  properties: {
    filters: {
      type: 'object',
      properties: {
        executionMonth: { type: 'string' },
        executionYear: { type: 'string' },
        executionQuarter: { type: 'string' },
        invoiceMonth: { type: 'string' },
        invoiceQuarter: { type: 'string' },
        invoiceYear: { type: 'string' },
      },
      required: ['executionMonth', 'executionYear', 'executionQuarter', 'invoiceMonth', 'invoiceYear', 'invoiceQuarter'],
    },
    visualization: {
      type: 'object',
      properties: {
        table: { type: 'string' },
        dimensions: {
          type: 'object',
          properties: {
            company: { type: 'string', nullable: true },
            customerHierarchy: { type: 'string', nullable: true },
            wasteDetails: { type: 'string', nullable: true },
            orderDetails: { type: 'string', nullable: true },
            invoiceDetails: { type: 'string', nullable: true },
            measures: { type: 'string', nullable: true },
          },
        },
      },
      required: ['table', 'dimensions'],
    },
  },
  required: ['filters', 'visualization'],
};

const appConfigSchema: JSONSchemaType<CostReportAppConfig> = {
  $id: 'app-config-schema',
  type: 'object',
  properties: {
    appId: { type: 'string' },
    objectIds: { type: 'object', $ref: 'objects-schema' },
    companyNameSelectionFieldName: { type: 'string' },
    defaultSelections: { type: 'array', items: defaultSelectionsSchema },
  },
  required: ['appId', 'companyNameSelectionFieldName', 'defaultSelections'],
};

const costReportConfigSchema: JSONSchemaType<CostReportConfig> = {
  type: 'object',
  properties: {
    en: {
      type: 'object',
      $ref: 'app-config-schema',
      required: ['objectIds'],
    },
    fi: { type: 'object', $ref: 'app-config-schema' },
    sv: { type: 'object', $ref: 'app-config-schema' },
  },
  required: ['en'],
};

export const costReportConfigSchemaValidator = () =>
  new Ajv().addSchema([defaultSelectionsSchema, objectsSchema, appConfigSchema]).compile(costReportConfigSchema);
