import { urls } from '@data/api/config';
import { Contract, ContractsByLocationsResponse, ContractsSitesResponse } from '@models/contract';
import { UserAccess } from '@models/user';
import { postData } from '@utils/data';
import { isUserAccessOfInternal } from '@utils/user';
import { Headers as HeadersConstants, MimeTypes } from 'http-constants-ts';
import { ContractsByLocationRequestParameters } from 'src/types/services';
import {
  ContractFiltersKeys,
  ContractsByLocationsRequestBody,
  ContractFiltersKeysWithPreFilteringFlagRequestBody,
} from '@models/service';

export const fetchContractDetails = async (
  contractNo: string,
  dataSource: string | null,
  language: string,
): Promise<Contract> => {
  const contentTypeHeader = new Map([[HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON]]);

  return postData(
    `${urls.contractDetails}/${contractNo}`,
    JSON.stringify({ language, dataSource: dataSource ?? '' }),
    contentTypeHeader,
  );
};

export const fetchContractsSites = async (
  filtersKeys: ContractFiltersKeys,
  userAccess: UserAccess,
  language: string,
  additionalFilteringForOrdersCreation = false,
): Promise<ContractsSitesResponse> => {
  const contentTypeHeader = new Map([[HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON]]);

  const body: ContractFiltersKeysWithPreFilteringFlagRequestBody = {
    language,
    filters: {
      wastes: filtersKeys.wastes.length > 0 ? filtersKeys.wastes : null,
      equipments: filtersKeys.equipments.length > 0 ? filtersKeys.equipments : null,
      search: filtersKeys.search !== '' ? filtersKeys.search : null,
      filterForOrderCreation: additionalFilteringForOrdersCreation || undefined,
    },
  };

  if (isUserAccessOfInternal(userAccess)) {
    const bodyWithBps = {
      businessPartnersIds: userAccess.selectedBusinessPartners.map(bp => bp.id),
      ...body,
    };

    return postData(urls.contractsSitesAdmin, JSON.stringify(bodyWithBps), contentTypeHeader);
  }

  return postData(urls.contractsSites, JSON.stringify(body), contentTypeHeader);
};

export const fetchContractsByLocations = async ({
  locationCodes,
  wastes,
  equipments,
  search,
  activePage,
  numberOfPageElements,
  language,
  filterForOrderCreation = false,
}: ContractsByLocationRequestParameters): Promise<ContractsByLocationsResponse> => {
  const contentTypeHeader = new Map([[HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON]]);

  const queryParams = `?page=${activePage - 1}&size=${numberOfPageElements}`;

  const body: ContractsByLocationsRequestBody = {
    language,
    locationCodes,
    filters: {
      wastes: wastes.length > 0 ? wastes : null,
      equipments: equipments.length > 0 ? equipments : null,
      search: search !== '' ? search : null,
      filterForOrderCreation: filterForOrderCreation || undefined,
    },
  };

  return postData(`${urls.contractsByLocations}${queryParams}`, JSON.stringify(body), contentTypeHeader);
};
