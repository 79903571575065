import type { MapLocation } from '@models/home';
import { UserRole } from '@models/user';

const frontEndUXInsightsPrefix = 'FE_UX:';

const singleClickEventSuffix = 'SINGLE_CLICK';
const selectEventSuffix = 'SELECT';
const searchUsedEventSuffix = 'SEARCH_USED';

export interface InsightEvents {
  successfulLogin: { userRole: UserRole };
  loginPerCompany: { companyName: string };

  // Menu
  menuHomeClicked: null;
  menuOrderTrackingClicked: null;
  menuOrderCreationClicked: null;
  menuMyServicesClicked: null;
  menuReportsDashboardClicked: null;
  menuReportsWasteDetailsClicked: null;
  menuReportsCostDetailsClicked: null;
  menuReportsCO2Clicked: null;
  menuContactUsClicked: null;

  // Home
  homeLocationElementClicked: {
    locationBusinessPartnerName: MapLocation['businessPartnerName'];
  };
  homeQuickAccessOrderTrackingTrackOrdersClicked: null;
  homeQuickAccessCreateNewOrderClicked: null;
  homeQuickAccessMyServicesServiceViewClicked: null;
  homeQuickAccessReportsDashboardClicked: null;
  homeQuickAccessReportsWasteDetailsClicked: null;
  homeQuickAccessReportsCostDetailsClicked: null;
  homeQuickAccessReportsCO2DetailsClicked: null;
  homeInfoSafetyTrainingClicked: null;
  homeInfoFollowUsClicked: null;
  homeInfoContactUsClicked: null;

  // Order_Tracking
  /**
   * These filters are applied automatically when user selects a value.
   *
   * These filters are also a part of filters drawer.
   */
  ordersTrackingFilterTimePeriodSelected: { selectedValue: string };
  ordersTrackingFilterStatusSelected: { selectedValue: string };
  ordersTrackingFilterWasteTypeSelected: { selectedValue: string };

  /**
   * These filters could be applied only from the filters drawer, so we want to record
   * them only when user clicks `Apply` button.
   */
  ordersTrackingFilterOrderTypeSelected: { selectedValue: string };
  ordersTrackingFilterTransportTypeSelected: { selectedValue: string };
  ordersTrackingFilterEquipmentTypeSelected: { selectedValue: string };
  ordersTrackingFilterCompanySelected: { selectedValue: string };
  ordersTrackingFilterCitySelected: { selectedValue: string };

  ordersTrackingSortingSelected: { selectedValue: string };

  ordersTrackingSearchUsedDebounced: { searchedValue: string };

  // My_Services
  myServicesFilterWasteTypeSelected: { selectedValue: string };
  myServicesFilterEquipmentTypeSelected: { selectedValue: string };

  myServicesSearchUsedDebounced: { searchedValue: string };

  // Order_Details
  orderDetailsServiceDetailsClicked: null;

  // Service_Details
  serviceDetailsOpenOrdersClicked: null;
}

export type InsightEventName = keyof InsightEvents;
export type InsightEventProps<T extends InsightEventName> = InsightEvents[T];

export const insightsEventToTitleMap: Record<InsightEventName, string> = {
  successfulLogin: `${frontEndUXInsightsPrefix} Successful logged in`,
  loginPerCompany: `${frontEndUXInsightsPrefix} Company user logged in`,

  // Menu
  menuHomeClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Menu - home`,
  menuOrderTrackingClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Menu - order tracking`,
  menuOrderCreationClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Menu - order creation`,
  menuMyServicesClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Menu - my services`,
  menuReportsDashboardClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Menu - reports - dashboard`,
  menuReportsWasteDetailsClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Menu - reports - waste details`,
  menuReportsCostDetailsClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Menu - reports - cost details`,
  menuReportsCO2Clicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Menu - reports - CO2`,
  menuContactUsClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Menu - contact us`,

  // Home
  homeLocationElementClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Home - location element`,
  homeQuickAccessOrderTrackingTrackOrdersClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Home - quick access - order tracking - track orders`,
  homeQuickAccessCreateNewOrderClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Home - quick access - create new order`,
  homeQuickAccessMyServicesServiceViewClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Home - quick access - my services - service view`,
  homeQuickAccessReportsDashboardClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Home - quick access - reports - dashboard`,
  homeQuickAccessReportsWasteDetailsClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Home - quick access - reports - waste details`,
  homeQuickAccessReportsCostDetailsClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Home - quick access - reports - cost details`,
  homeQuickAccessReportsCO2DetailsClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Home - quick access - reports - CO2 details`,
  homeInfoSafetyTrainingClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Home - info - safety training`,
  homeInfoFollowUsClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Home - info - follow us`,
  homeInfoContactUsClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Home - info - contact us`,

  // Order_Tracking
  ordersTrackingFilterTimePeriodSelected: `${frontEndUXInsightsPrefix} ${selectEventSuffix}: Order_Tracking - filter - time period`,
  ordersTrackingFilterStatusSelected: `${frontEndUXInsightsPrefix} ${selectEventSuffix}: Order_Tracking - filter - status`,
  ordersTrackingFilterWasteTypeSelected: `${frontEndUXInsightsPrefix} ${selectEventSuffix}: Order_Tracking - filter - waste type`,
  ordersTrackingFilterOrderTypeSelected: `${frontEndUXInsightsPrefix} ${selectEventSuffix}: Order_Tracking - filter - order type`,
  ordersTrackingFilterTransportTypeSelected: `${frontEndUXInsightsPrefix} ${selectEventSuffix}: Order_Tracking - filter - transport type`,
  ordersTrackingFilterEquipmentTypeSelected: `${frontEndUXInsightsPrefix} ${selectEventSuffix}: Order_Tracking - filter - equipment type`,
  ordersTrackingFilterCompanySelected: `${frontEndUXInsightsPrefix} ${selectEventSuffix}: Order_Tracking - filter - company`,
  ordersTrackingFilterCitySelected: `${frontEndUXInsightsPrefix} ${selectEventSuffix}: Order_Tracking - filter - city`,

  ordersTrackingSortingSelected: `${frontEndUXInsightsPrefix} ${selectEventSuffix}: Order_Tracking - sorting`,

  ordersTrackingSearchUsedDebounced: `${frontEndUXInsightsPrefix} ${searchUsedEventSuffix}: Order_Tracking`,

  // My_Services
  myServicesFilterWasteTypeSelected: `${frontEndUXInsightsPrefix} ${selectEventSuffix}: My_Services - filter - waste type`,
  myServicesFilterEquipmentTypeSelected: `${frontEndUXInsightsPrefix} ${selectEventSuffix}: My_Services - filter - equipment type`,

  myServicesSearchUsedDebounced: `${frontEndUXInsightsPrefix} ${searchUsedEventSuffix}: My_Services`,

  // Order_Details
  orderDetailsServiceDetailsClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Order_Details - service details`,

  // Service_Details
  serviceDetailsOpenOrdersClicked: `${frontEndUXInsightsPrefix} ${singleClickEventSuffix}: Service_Details - open orders`,
};
