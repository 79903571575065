import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { StyledErrorView, StyledPagination, TableWithPaginationContainer } from './styles';
import { UsersProfilesTable } from './UsersProfilesTable';
import { paginationHeights, PaginationSize } from '@components/Pagination';
import { useUsersProfiles } from '@data/hooks/useUsersProfiles';
import { INITIAL_PAGE_NUMBER, TOTAL_PAGES_ON_DATA_LOADING } from '@common/consts';
import { UsersProfilesRequestParams } from 'src/types/usersManagement';
import { UserProfile } from '@models/usersManagement';
import { UsersProfilesFiltersWithSearch } from 'src/types/usersManagement';
import { scrollToTop } from '@utils/general';

const NUMBER_OF_PAGE_ELEMENTS = 25;
const PAGINATION_SIZE: PaginationSize = 'l';
const PAGINATION_HEIGHT_PX = paginationHeights[PAGINATION_SIZE];
const TABLE_ID = 'users-table';

type UsersProfilesTableWithPaginationProps = UsersProfilesFiltersWithSearch & {
  pageHeaderHeight: string | undefined;
  onRowClick: (user: UserProfile) => void;
  selectedUserId: string | undefined;
};

export const UsersProfilesTableWithPagination: FC<UsersProfilesTableWithPaginationProps> = ({
  companies,
  search,
  onRowClick,
  selectedUserId,
  pageHeaderHeight,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [totalPaginationPages, setTotalPaginationPages] = useState<number>(TOTAL_PAGES_ON_DATA_LOADING);

  const [usersRequestParams, setUsersRequestParams] = useState<UsersProfilesRequestParams>({
    numberOfPageElements: NUMBER_OF_PAGE_ELEMENTS,
    activePage: INITIAL_PAGE_NUMBER,
    companies,
    search,
  });

  const { data: usersProfilesResponse, isLoading, isPending, error } = useUsersProfiles(usersRequestParams);

  useEffect(() => {
    const shouldRestoreActivePage = totalPaginationPages === TOTAL_PAGES_ON_DATA_LOADING;

    setUsersRequestParams(prev => ({
      ...prev,
      search,
      companies,
      activePage: shouldRestoreActivePage ? prev.activePage : INITIAL_PAGE_NUMBER,
    }));

    setTotalPaginationPages(TOTAL_PAGES_ON_DATA_LOADING);
  }, [search, companies]);

  useEffect(() => {
    if (usersProfilesResponse) {
      setTotalPaginationPages(Math.ceil(usersProfilesResponse.metadata.total / NUMBER_OF_PAGE_ELEMENTS));
    }
  }, [usersProfilesResponse?.metadata.total]);

  const setActivePage = useCallback((page: number) => {
    setUsersRequestParams(prev => ({ ...prev, activePage: page }));

    scrollToTop(containerRef.current?.querySelector(`#${TABLE_ID}`));
  }, []);

  const shouldDisplayTable = isLoading || isPending || !!usersProfilesResponse;
  const shouldHidePagination = totalPaginationPages <= 1;
  const paginationDisplayed = (!error && !!usersProfilesResponse && usersProfilesResponse.metadata.total > 0) || isLoading;

  return (
    <TableWithPaginationContainer ref={containerRef}>
      {shouldDisplayTable ? (
        <UsersProfilesTable
          id={TABLE_ID}
          onRowClick={onRowClick}
          selectedUserId={selectedUserId}
          isLoading={isLoading || isPending}
          usersProfiles={usersProfilesResponse?.response.data ?? []}
          pageHeaderHeight={pageHeaderHeight}
          paginationHeight={paginationDisplayed ? PAGINATION_HEIGHT_PX : '0px'}
        />
      ) : (
        <StyledErrorView />
      )}

      {paginationDisplayed && (
        <StyledPagination
          size={PAGINATION_SIZE}
          totalPages={totalPaginationPages}
          activePage={usersRequestParams.activePage}
          setActivePage={setActivePage}
          hidePagination={shouldHidePagination}
        />
      )}
    </TableWithPaginationContainer>
  );
};
