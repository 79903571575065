import { InsightEventName } from '@common/statistics';
import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import { BaseSyntheticEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavItem, SubNavigation } from './NavItem';
import { isCostReportToggleOn, isDashboardReportToggleOn, isWasteReportToggleOn } from '@utils/featureToggles';
import { User } from '@models/user';

interface ReportsSubNavigationProps {
  onItemClick: (e: BaseSyntheticEvent, insightEvent: InsightEventName) => void;
  user: User;
  disabled?: boolean;
}

const reportsSubnavigationPaths = [paths.costDetailsReport, paths.wasteDetailsReport, paths.dashboardReport];

export const ReportsSubNavigation: FC<ReportsSubNavigationProps> = ({ disabled, user, onItemClick }) => {
  const { t } = useTranslation<Namespace[]>(['mainLayout', 'reports']);

  return (
    <SubNavigation description={t('mainLayout:reports')} disabled={disabled} subnavigationPaths={reportsSubnavigationPaths}>
      {isDashboardReportToggleOn(user) && (
        <NavItem
          data-testid="dashboard-report-nav-item"
          to={paths.dashboardReport}
          onClick={e => onItemClick(e, 'menuReportsDashboardClicked')}
          description={t('reports:dashboard.navigation')}
          disabled={disabled}
          subNavItem={true}
        />
      )}

      {isWasteReportToggleOn(user) && (
        <NavItem
          data-testid="waste-qty-report-nav-item"
          to={paths.wasteDetailsReport}
          onClick={e => onItemClick(e, 'menuReportsWasteDetailsClicked')}
          description={t('reports:wasteQty.navigation')}
          disabled={disabled}
          subNavItem={true}
        />
      )}

      {isCostReportToggleOn(user) && (
        <NavItem
          data-testid="cost-report-nav-item"
          to={paths.costDetailsReport}
          onClick={e => onItemClick(e, 'menuReportsCostDetailsClicked')}
          description={t('reports:cost.navigation')}
          disabled={disabled}
          subNavItem={true}
        />
      )}
    </SubNavigation>
  );
};
