import { City, Company, ExactAddressDetails, Service } from '@models/service';
import { sum } from 'lodash';
import { destructureContractIdentifier } from './routes';
import { countriesWithOrdersCreationSupport, serviceTypesWithOrdersCreationSupport } from '@config/ordersCreation';
import { getDataSourceByCountry, hasValue } from './dataOperations';
import { isSupportedServiceTypeValue } from './contract';
import { LocationTreeDetails } from 'src/types/services';
import { validateAndJoin } from './strings';

const getContractsAmountInCompany = (company: Company) => sum(company.addresses.map(address => address.numberOfContracts));

const getContractsAmountInCity = (city: City) => sum(city.companies.map(getContractsAmountInCompany));

export const getContractsAmountInService = (service: Service) => sum(service.cities.map(getContractsAmountInCity));

export const hasContracts = (city: City) =>
  city.companies.some(company => company.addresses.some(address => address.numberOfContracts !== 0));

export const groupCitiesByLocationCode = (cities: City[]) => {
  const resultMap: Record<string, LocationTreeDetails> = {};

  cities.forEach(city =>
    city.companies.forEach(company =>
      company.addresses.forEach(address =>
        address.exactAddresses.forEach(exactAddress => {
          const locationExactDescription = getLocationExactDescription(
            address.locationSpecificName,
            address.addressLine1,
            address.addressLine2,
            exactAddress.name,
          );

          resultMap[exactAddress.locationCode] = {
            locationCode: exactAddress.locationCode,
            locationExactDescription,
            companyName: company.name,
            numberOfContracts: exactAddress.numberOfContracts.toString(),
          };
        }),
      ),
    ),
  );
  return resultMap;
};

export const findExactAddressDetailsByLocationCode = (exactAddresses: ExactAddressDetails[], locationCode: string) =>
  exactAddresses.find(exactAddress => exactAddress.locationCode === locationCode);

export const findCompanyByLocationCode = (companies: Company[], locationCode: string) =>
  companies.find(company =>
    company.addresses.find(address => findExactAddressDetailsByLocationCode(address.exactAddresses, locationCode)),
  );

export const getContractsNumberAmountLabel = (numberOfContracts: number, totalNumberOfContracts: number) =>
  numberOfContracts === totalNumberOfContracts ? numberOfContracts.toString() : `${numberOfContracts}/${totalNumberOfContracts}`;

export const shouldAllowOrdersCreation = (contractIdentifier: string, serviceType: string) => {
  if (!isSupportedServiceTypeValue(serviceType)) return false;
  const { dataSource } = destructureContractIdentifier(contractIdentifier);

  if (!dataSource) return false;

  return (
    countriesWithOrdersCreationSupport.map(getDataSourceByCountry).includes(dataSource) &&
    serviceTypesWithOrdersCreationSupport.includes(serviceType)
  );
};

export const getLocationDescription = (locationSpecificName: string, addressLine1: string, addressLine2: string) =>
  validateAndJoin([locationSpecificName, addressLine1, addressLine2]);

export const getLocationExactDescription = (
  locationSpecificName: string,
  addressLine1: string,
  addressLine2: string,
  exactLocation: string,
) => {
  const addressElements = [locationSpecificName, addressLine1, addressLine2].filter(hasValue);
  const address = getLocationDescription(locationSpecificName, addressLine1, addressLine2);

  return addressElements.includes(exactLocation) ? address : `${address} ${exactLocation}`;
};
