import { Multiselect } from '@components/Multiselect';
import { horizontalContainer, verticalContainer } from '@components/styles';
import { Button, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const ContactFormContainer = styled.div`
  ${verticalContainer};
  flex: 1;
  align-items: center;
  padding: 0 12px;
  max-width: 448px;
`;

export const Form = styled.div`
  ${verticalContainer};
  margin: ${spacing.xs} 0;

  width: 100%;

  row-gap: ${spacing.xxs};
`;

export const SitesInputContainer = styled.div`
  ${verticalContainer};
  row-gap: ${spacing.xxxs};
`;

export const SubmitContainer = styled.div`
  ${horizontalContainer};
  align-items: center;
  column-gap: ${spacing.xxs};
`;

export const SubmitButton = styled(Button)`
  white-space: nowrap;
  align-self: center;
`;

export const StyledMultiselect: typeof Multiselect = styled(Multiselect)`
  li {
    padding: 12px 0px;
    box-sizing: border-box;
  }

  li > label > span {
    white-space: pre-wrap;
    line-height: 1.7rem;
  }
`;
