import { ContractLocation } from '@models/contract';
import { getContractLocationDescriptionForContactForm } from './dataOperations/address';
import { uniqBy } from 'lodash';

export const mapToLocationSelectItems = (locations: ContractLocation[]) => {
  const items = locations.map(loc => ({
    name: getContractLocationDescriptionForContactForm(loc),
    value: loc.code,
  }));

  return uniqBy(items, item => item.name);
};
