import { NoResultsNote } from '@components/index';
import { ErrorView } from '@components/layout';
import { Pagination } from '@components/Pagination';
import { CONTAINER_BORDER_RADIUS, Table, VerticalContainer } from '@components/styles';
import { colors, CommonBoxProps, CssValue, fontWeights, spacing, TableProps } from '@fortum/elemental-ui';
import { UserProfile } from '@models/usersManagement';
import styled from 'styled-components';

export const TableWithPaginationContainer = styled(VerticalContainer)`
  flex: 1;

  background-color: ${colors.snowWhite};
`;

type StyledTableProps = TableProps<UserProfile> & {
  $paginationHeight: CssValue;
  $pageHeaderHeight: CssValue;
};

export const StyledTable = styled(Table)<StyledTableProps>`
  --padding-horizontal: 80px;

  padding: 0 var(--padding-horizontal);
  height: ${props => `calc(100vh - ${props.$pageHeaderHeight} - var(--app-header-height) - ${props.$paginationHeight})`};

  width: calc(100% - 2 * var(--padding-horizontal));

  table {
    width: 100%;
  }
`;

export const narrowCellContainerStyle: CommonBoxProps = {
  minWidth: '165px',
};

export const nameCellContainerStyle: CommonBoxProps = {
  ...narrowCellContainerStyle,
  fontWeight: fontWeights.medium,
};

export const wideCellContainerStyle: CommonBoxProps = {
  minWidth: '320px',
};

export const StyledPagination = styled(Pagination)`
  z-index: 2;

  box-shadow: 0 -6px 15px 0 rgba(0, 0, 0, 0.15);

  border-bottom-left-radius: ${CONTAINER_BORDER_RADIUS};
  border-bottom-right-radius: ${CONTAINER_BORDER_RADIUS};
`;

export const StyledErrorView = styled(ErrorView)`
  flex: unset;
  margin: auto;
`;

export const StyledNoResultsNote = styled(NoResultsNote)`
  margin-top: 134px;
  margin-bottom: ${spacing.xs};
`;
