import { KeyValuePair, Nullable } from 'src/types/general';
import type { FiltersRequestBody, MetaFiltersRequestBody } from 'src/types/requests';
import { GeneralAddress, ExactAddress } from './common';

export interface ExactAddressDetails extends ExactAddress {
  numberOfContracts: number;
  totalNumberOfContracts: number;
}

type ServiceAddress = Omit<GeneralAddress, 'city' | 'zipCode'>;

export interface ServiceAddressDetails extends ServiceAddress {
  exactAddresses: ExactAddressDetails[];
  numberOfContracts: number;
  totalNumberOfContracts: number;
}

export interface Company {
  name: string;
  addresses: ServiceAddressDetails[];
}

export interface City {
  name: string;
  companies: Company[];
}

export interface Service {
  cities: City[];
}

export interface Services {
  services: ServiceType[];
}

export interface ServiceType {
  type: string;
  contracts: Detail[];
}

export interface Detail {
  dataSource: string;
  wasteDescription: string;
  containerType: string;
  transportType: string;
  cycleType: string;
  queue: number;
  contractNo: string;
}

export const serviceTypeValues = ['Waste collection service', 'Waste receiving service', 'Equipment sales service'] as const;

export type ServiceTypeValue = (typeof serviceTypeValues)[number];
export interface ContractMetaFilters {
  wastes: KeyValuePair[];
  equipments: KeyValuePair[];
}

export type ContractFiltersRequestBody = FiltersRequestBody<Nullable<ContractFiltersKeys>>;

export type ContractMetaFiltersRequestBody = MetaFiltersRequestBody<Nullable<ContractMetaFiltersKeys>>;

export type ContractFiltersKeysWithPreFilteringFlagRequestBody = FiltersRequestBody<
  Nullable<ContractFiltersKeysWithPreFilteringFlag>
>;

export type ContractsByLocationsRequestBody = ContractFiltersKeysWithPreFilteringFlagRequestBody & {
  locationCodes: string[];
};

export interface ContractFiltersKeys {
  wastes: KeyValuePair['key'][];
  equipments: KeyValuePair['key'][];
  search: string;
}

export interface ContractFiltersKeysWithPreFilteringFlag extends ContractFiltersKeys {
  filterForOrderCreation?: boolean;
}

interface ContractMetaFiltersKeys {
  equipmentsByWaste: KeyValuePair['key'][];
  wastesByEquipment: KeyValuePair['key'][];
  filterForOrderCreation?: boolean;
}
