import { OrderFilters, OrdersSelectedFiltersWithSearch } from '@models/filters';
import { OngoingOrdersSummaryResponse, OrderDetails, OrdersCreationResponse, OrdersOverviewResponse } from '@models/orders';
import { HandlingStatusMap } from '@config/order';
import { UserAccess } from '@models/user';
import { deleteData, getAuthHeaders, getData, postData } from '@utils/data';
import { isUserAccessOfInternal } from '@utils/user';
import { Headers as HeadersConstants, MimeTypes } from 'http-constants-ts';
import { flatten } from 'lodash';
import { urls } from './config';
import { CreateOrdersParams } from 'src/types/ordersCreation';
import { OrderNoUpdateRequestBody } from '@models/orders';
import { mapCreationFormsToRequestBody } from '@utils/ordersCreation';
import { HttpError } from '@common/http';
import { StorageUtils } from '@common/storage';

const appJsonContentTypeHeader = new Map([[HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON]]);
export const fetchOngoingOrdersSummary = (userAccess: UserAccess): Promise<OngoingOrdersSummaryResponse> => {
  if (isUserAccessOfInternal(userAccess)) {
    const businessPartnerCodes = userAccess.selectedBusinessPartners.map(bp => bp.id);
    return postData<OngoingOrdersSummaryResponse>(
      urls.ongoingOrdersSummaryForAdmin,
      JSON.stringify({
        businessPartnerCodes,
      }),
      appJsonContentTypeHeader,
    );
  }

  return getData<OngoingOrdersSummaryResponse>(urls.ongoingOrdersSummary);
};

/**
 * Different order status codes can have same translations, e.g. O27_PICKED and O29_DELIVE are both translated to 'In transportation'
 * Since selectItems for Order Status Multiselect are created by using uniqBy function (see mapOrderStatusToSelectItems function),
 * re-mapping has to be performed here to get all order status codes, that were lost by uniqBy
 */
export const fetchOrders = async (
  userAccess: UserAccess,
  orderFilters: OrdersSelectedFiltersWithSearch,
  activePage: number,
  numberOfPageElements: number,
  sortBy: string,

  language: string,
): Promise<OrdersOverviewResponse> => {
  const query = `?page=${activePage - 1}&size=${numberOfPageElements}`;

  const allStatuses = flatten(orderFilters.orderStatus.map(status => HandlingStatusMap[status]));

  //TODO try to find better solution for empty filters
  const filters = {
    orderStatus: allStatuses.length === 0 ? null : allStatuses,
    orderType: orderFilters.orderType.length === 0 ? null : orderFilters.orderType,
    city: orderFilters.city.length === 0 ? null : orderFilters.city,
    containerType: orderFilters.containerType.length === 0 ? null : orderFilters.containerType,
    transportType: orderFilters.transportType.length === 0 ? null : orderFilters.transportType,
    businessPartner: orderFilters.businessPartner.length === 0 ? null : orderFilters.businessPartner,
    wasteDescription: orderFilters.wasteDescription.length === 0 ? null : orderFilters.wasteDescription,
    search: orderFilters.search === '' ? null : orderFilters.search,
    timePeriod: orderFilters.timePeriod,
  };

  if (isUserAccessOfInternal(userAccess)) {
    return postData(
      `${urls.orderInfoAdmin}${query}`,
      JSON.stringify({
        businessPartnersIds: userAccess.selectedBusinessPartners.map(bp => bp.id),
        language,
        sortBy,
        filters,
      }),
      appJsonContentTypeHeader,
    );
  }
  return postData(
    `${urls.orderInfo}${query}`,
    JSON.stringify({
      language,
      sortBy,
      filters,
    }),
    appJsonContentTypeHeader,
  );
};

export const fetchOrderFilters = (
  userAccess: UserAccess,
  precedence: { key: string; order: number }[],
  selectedFilters: OrdersSelectedFiltersWithSearch,
  language: string,
): Promise<OrderFilters> => {
  const isInternalAdmin = isUserAccessOfInternal(userAccess);
  const url = isInternalAdmin ? urls.ordersFiltersForAdmin : urls.ordersFilters;

  const allStatuses = flatten(selectedFilters.orderStatus.map(status => HandlingStatusMap[status]));

  const body = {
    language,
    metaFilters: {
      filters: { ...selectedFilters, orderStatus: allStatuses },
      precedence,
    },
    businessPartnersIds: isInternalAdmin ? userAccess.selectedBusinessPartners.map(bp => bp.id) : null,
  };

  return postData(url, JSON.stringify(body), appJsonContentTypeHeader);
};

export const fetchOrderDetails = (orderNo: string, language: string) => {
  const url = `${urls.orderDetails}/${orderNo}`;

  return postData<OrderDetails>(
    url,
    JSON.stringify({
      language,
    }),
    appJsonContentTypeHeader,
  );
};

export const createOrders = async ({ creationForms, language }: CreateOrdersParams) => {
  const idToken = sessionStorage.getItem(StorageUtils.sessionKeys.idToken);
  if (!idToken) throw Error('Id token not found');

  const createOrdersAdditionalHeaders = new Map([[HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON]]);

  const body = mapCreationFormsToRequestBody(creationForms, language);

  return postData<OrdersCreationResponse>(urls.ordersCreation, JSON.stringify(body), createOrdersAdditionalHeaders);
};

export const overwriteTemporaryOrderNumber = async (body: OrderNoUpdateRequestBody) => {
  const headers = getAuthHeaders();

  appJsonContentTypeHeader?.forEach((value: string, key: string) => {
    headers.append(key, value);
  });

  const response = await fetch(urls.orderNoChange, {
    method: 'POST',
    mode: 'cors',
    headers,
    body: JSON.stringify(body),
  });

  const responseBody = await response.json();

  if (response.ok) {
    return responseBody;
  }

  throw new HttpError(response.status, responseBody.msg ?? response.statusText);
};

export const deleteOrder = async (orderNo: string) => deleteData<void>(`${urls.orderManagement}/${orderNo}`);
