import { translate } from '@utils/internationalization';
import { DescriptionList } from '@fortum/elemental-ui';
import { GeneralInfo, WasteDetails } from '@models/contract';
import { t } from 'i18next';
import { FC } from 'react';
import { hasValue } from '@utils/dataOperations';
import { displayedValue, getHazardousInfoDescription, NO_DATA_BADGE } from '@utils/dataOperations';
import { Tile } from '@components/Tile/Tile';
import { MediumDescriptionListItem, StyledDescriptionListItem } from '@components/styles';
import { getListItemTestId, getTileTestId } from '@utils/testsHelpers';
import { WasteEwcCode, ADRLabels, HPCodes, WasteConsistency, WasteRdCode } from '@components/wasteDetails';

interface WasteDetailsTileProps {
  generalInfo: GeneralInfo;
  wasteDetails: WasteDetails;
}

export const WasteDetailsTile: FC<WasteDetailsTileProps> = ({ generalInfo, wasteDetails }) => {
  const animalicByProductValue =
    wasteDetails.animalicByProduct === null
      ? NO_DATA_BADGE
      : translate(`${wasteDetails.animalicByProduct}`, 'domain', 'animalicByProduct');

  const adrValue = wasteDetails.adr === null ? NO_DATA_BADGE : translate(`${wasteDetails.adr}`, 'domain', 'adrWaste');

  return (
    <Tile header={t('wasteDetails:wasteDetails.details')} data-testid={getTileTestId('waste-details')}>
      <DescriptionList size="condensed">
        <MediumDescriptionListItem
          data-testid={getListItemTestId('waste-description')}
          label={t('wasteDetails:wasteDescription')}
        >
          {displayedValue(generalInfo.wasteDescription)}
        </MediumDescriptionListItem>

        <StyledDescriptionListItem
          data-testid={getListItemTestId('waste-consistency')}
          label={t('wasteDetails:wasteDetails.wasteConsistency')}
        >
          <WasteConsistency consistency={wasteDetails.consistency} />
        </StyledDescriptionListItem>

        <StyledDescriptionListItem data-testid={getListItemTestId('ewc-code')} label={t('wasteDetails:wasteDetails.ewcCode')}>
          <WasteEwcCode ewcCode={wasteDetails.ewcCode} />
        </StyledDescriptionListItem>

        <StyledDescriptionListItem data-testid={getListItemTestId('hazard')} label={t('wasteDetails:wasteDetails.hazard')}>
          {getHazardousInfoDescription(wasteDetails.hazard)}
        </StyledDescriptionListItem>

        <StyledDescriptionListItem data-testid={getListItemTestId('rd-code')} label={t('wasteDetails:wasteDetails.rdCode')}>
          <WasteRdCode code={wasteDetails.rdCode} />
        </StyledDescriptionListItem>

        <StyledDescriptionListItem
          data-testid={getListItemTestId('hazardous-material-classes')}
          label={t('wasteDetails:wasteDetails.adrLabels')}
        >
          <ADRLabels
            hazardousMaterialClasses={wasteDetails.hazardousMaterialClasses}
            environmentalHazardous={wasteDetails.environmentalHazardous}
          />
        </StyledDescriptionListItem>

        <StyledDescriptionListItem data-testid={getListItemTestId('un-number')} label={t('wasteDetails:wasteDetails.unNumber')}>
          {displayedValue(wasteDetails.unNumber)}
        </StyledDescriptionListItem>

        {wasteDetails.adrNosComment && (
          <StyledDescriptionListItem
            data-testid={getListItemTestId('adr-nos-comment')}
            label={t('wasteDetails:wasteDetails.adrNosComment')}
          >
            {wasteDetails.adrNosComment}
          </StyledDescriptionListItem>
        )}

        <StyledDescriptionListItem data-testid={getListItemTestId('adr-waste')} label={t('wasteDetails:wasteDetails.adrWaste')}>
          {adrValue}
        </StyledDescriptionListItem>

        <StyledDescriptionListItem
          data-testid={getListItemTestId('environmental-hazardous')}
          label={t('wasteDetails:wasteDetails.environmentalHazardous')}
        >
          {translate(
            displayedValue(wasteDetails.environmentalHazardous !== null ? `${wasteDetails.environmentalHazardous}` : null),
            'domain',
            'environmentallyHazardous',
          )}
        </StyledDescriptionListItem>

        <StyledDescriptionListItem data-testid={getListItemTestId('hp-codes')} label={t('wasteDetails:wasteDetails.hpCodes')}>
          <HPCodes hpCodes={wasteDetails.hpCodes} />
        </StyledDescriptionListItem>

        {hasValue(wasteDetails.oilType) && (
          <StyledDescriptionListItem
            data-testid={getListItemTestId('waste-oil-type')}
            label={t('wasteDetails:wasteDetails.wasteOilType')}
          >
            {translate(wasteDetails.oilType, 'domain', 'wasteOilType')}
          </StyledDescriptionListItem>
        )}

        {hasValue(wasteDetails.popCompounds) && (
          <StyledDescriptionListItem
            data-testid={getListItemTestId('pop-compounds')}
            label={t('wasteDetails:wasteDetails.popCompounds')}
          >
            {translate(wasteDetails.popCompounds, 'domain', 'analysisPopCode')}
          </StyledDescriptionListItem>
        )}

        <StyledDescriptionListItem
          data-testid={getListItemTestId('animalic-by-product')}
          label={t('wasteDetails:wasteDetails.animalicByProduct')}
        >
          {animalicByProductValue}
        </StyledDescriptionListItem>
      </DescriptionList>
    </Tile>
  );
};
