import { TokenRefreshUtils } from '@common/auth';
import { QueryKeys } from '@common/query';
import { GlobalBanner, NotificationBanner } from '@components/index';
import { AppHeader, AppNavbar } from '@components/layout';
import {
  ApplicationContainer,
  ApplicationInnerContainer,
  CenteredLoader,
  OutletContainer,
  RightSideContainer,
} from '@components/layout/styles';
import { Notification, useNotification } from '@components/Notification';
import { Namespace } from '@config/i18n';
import { fullScreenPaths, paths } from '@config/routes';
import { getUserInfoQueryOptions, useUserInfo } from '@data/hooks/useUserInfo';
import { useAuthProvider, useVersionCheck } from '@hooks/index';
import { QueryClient } from '@tanstack/react-query';
import { areAuthTokensAvailable } from '@utils/auth';
import { bpsSelectionMissing, getUserAccessInfo } from '@utils/user';
import { FC, StrictMode, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Unauthorized } from './errors/unauthorized/unauthorized';

export const loader = (queryClient: QueryClient) => async () => {
  const query = getUserInfoQueryOptions();
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query));
};

const Content: FC = () => {
  const { status, fetchStatus } = useUserInfo();

  if (status === 'pending') return <CenteredLoader />;

  if (status === 'error') return <Unauthorized />;

  return <OutletContainer>{status === 'success' && fetchStatus !== 'idle' ? <CenteredLoader /> : <Outlet />}</OutletContainer>;
};

const Root: FC = () => {
  const { t } = useTranslation<Namespace[]>(['common', 'errors']);
  const location = useLocation();
  const navigate = useNavigate();

  const { displayNotification, setDisplayErrorNotification, closeNotification } = useNotification();
  const { isOutdated, triggerVersionUpdate } = useVersionCheck();

  const { data: userInfo, status } = useUserInfo();
  const { logout } = useAuthProvider();

  const userAccess = userInfo && getUserAccessInfo();
  const areBpsSelectionMissing = bpsSelectionMissing(userAccess);

  useEffect(() => {
    if (userInfo && !userInfo.agreementAccepted) navigate(paths.termsAndConditions);

    if (areBpsSelectionMissing) navigate(paths.selectCustomer);
  }, [userInfo]);

  const handleLogout = useCallback(() => {
    TokenRefreshUtils.onLogout(QueryKeys.userInfo);
    logout().catch(() => setDisplayErrorNotification());
  }, [logout]);

  const showNavBar = !fullScreenPaths.includes(location.pathname);

  if (areAuthTokensAvailable() || !TokenRefreshUtils.tokenRefreshAvailable(QueryKeys.userInfo)) {
    return (
      <>
        <ApplicationContainer>
          <AppHeader user={userInfo} logout={handleLogout} />
          <ApplicationInnerContainer>
            {showNavBar && <AppNavbar disabled={status !== 'success' || areBpsSelectionMissing} />}
            <RightSideContainer>
              {isOutdated && (
                <NotificationBanner
                  message={t('common:update_info')}
                  type="info"
                  ctaText={t('common:update_cta')}
                  ctaOnClick={triggerVersionUpdate}
                />
              )}
              <GlobalBanner />
              <Content />
            </RightSideContainer>
          </ApplicationInnerContainer>
        </ApplicationContainer>
        <Notification
          type="error"
          content={t('error:logout')}
          opened={displayNotification === 'error'}
          onClose={closeNotification}
          maxWidth="30rem"
        />
      </>
    );
  }

  return null;
};

const WithStrictMode: FC = () => (
  <StrictMode>
    <Root />
  </StrictMode>
);

export default WithStrictMode;
