import { VerticalContainer } from '@components/styles';
import { ContentText } from '@fortum/elemental-ui';
import { FC } from 'react';
import { smallTextStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';

export const ContactSection: FC = () => {
  const { t } = useTranslation<Namespace>('orderCreation');

  return (
    <VerticalContainer>
      <ContentText {...smallTextStyles}>{t('contactToChangeOrder')}</ContentText>
      <ContentText {...smallTextStyles}>{t('summaryPage.agreementInformation')}</ContentText>
    </VerticalContainer>
  );
};
