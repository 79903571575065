import { urls } from '@data/api/config';
import { MapLocations } from '@models/home';
import { UserAccess } from '@models/user';
import { getData, postData } from '@utils/data';
import { isUserAccessOfInternal } from '@utils/user';
import { Headers as HeadersConstants, MimeTypes } from 'http-constants-ts';

export const fetchMapLocations = async (userAccess: UserAccess): Promise<MapLocations> => {
  const contentTypeHeader = new Map([[HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON]]);

  if (isUserAccessOfInternal(userAccess)) {
    return postData(
      urls.locationsWithCoordinates,
      JSON.stringify({ businessPartnersIds: userAccess.selectedBusinessPartners.map(bp => bp.id) }),
      contentTypeHeader,
    );
  }

  return getData(urls.locationsWithCoordinatesOwn, contentTypeHeader);
};
