import { QueryKeys } from '@common/query';
import { fetchUsersProfilesFilters } from '@data/api/usersManagement';
import { QueryOptions } from 'src/types/reactQuery';
import { UsersProfilesFiltersResponse } from '@models/usersManagement';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';

const usersProfilesFiltersQuery = (): QueryOptions<UsersProfilesFiltersResponse> => ({
  queryKey: [QueryKeys.usersProfilesFilters],
  queryFn: fetchUsersProfilesFilters,
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useUsersProfilesFilters = () => useQuery<UsersProfilesFiltersResponse, Error>(usersProfilesFiltersQuery());
