import { Co2ReportObjects } from '@config/qlik';
import { FC } from 'react';
import { Section, SectionHeaderContainer, TableWithDimensionsContainer, DimensionsContainer } from './styles';
import { QlikObject, QlikSelectionObject } from '@components/qlik';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { ExportButton } from '../components';
import { ExportDataHandler } from '../types';

interface TableProps {
  objectIds: Co2ReportObjects;
  exportDataHandler: ExportDataHandler;
}

const TABLE_HEIGHT = '618px';

export const Table: FC<TableProps> = ({ objectIds, exportDataHandler }) => {
  const { t } = useTranslation<Namespace[]>(['reports', 'common']);

  return (
    <Section data-testid="table-section">
      <SectionHeaderContainer>
        <ExportButton text={t('common:export')} exportDataHandler={exportDataHandler} />
      </SectionHeaderContainer>

      <TableWithDimensionsContainer>
        <DimensionsContainer>
          <QlikSelectionObject dataTestId="dimension-company-info" id={objectIds.visualization.dimensions.companyInfo} />
          <QlikSelectionObject
            dataTestId="dimension-company-waste-details"
            id={objectIds.visualization.dimensions.wasteDetails}
          />
        </DimensionsContainer>

        <QlikObject dataTestId="co2-report-table" id={objectIds.visualization.table} height="100%" minHeight={TABLE_HEIGHT} />
      </TableWithDimensionsContainer>
    </Section>
  );
};
