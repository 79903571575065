import { useEffect, useRef, useState } from 'react';
import enigmaJs from 'enigma.js';
import schema from 'enigma.js/schemas/12.170.2.json';
import { buildWebsocketUrl, responseInterceptors } from './utils';
import { isRawQlikError, QlikError, QlikConnectionConfig } from '../../types';
import { useDebounceCallback } from 'usehooks-ts';

const APP_OPEN_DEBOUNCE_TIME_MS = 500;

interface UseEnigmaReturnValue {
  enigma: enigmaJS.IGeneratedAPI | null;
  enigmaError: QlikError | null;
}

export const useEnigma = (connectionConfig: QlikConnectionConfig, appId: string): UseEnigmaReturnValue => {
  const engineRef = useRef<enigmaJS.IGeneratedAPI | null>(null);

  const [enigmaError, setEnigmaError] = useState<QlikError | null>(null);
  const [engine, setEngine] = useState<enigmaJS.IGeneratedAPI | null>(null);
  const [temporaryEngine, setTemporaryEngine] = useState<enigmaJS.IGeneratedAPI | null>(null);

  const openSession = () => {
    const url = buildWebsocketUrl(connectionConfig, appId);

    const session = enigmaJs.create({
      schema,
      url,
      responseInterceptors,
    });
    session.on('suspended', () => {
      console.warn('Captured session suspended');
    });
    session.on('error', () => {
      console.warn('Captured session error');
      setEnigmaError(new QlikError('Captured session error', 'SESSION_ERROR'));
    });
    session.on('closed', () => {
      console.warn('Session was closed');
    });

    return session.open();
  };

  const closeSession = async () => {
    if (engineRef.current?.id === appId) return;

    setEnigmaError(null);
    setTemporaryEngine(null);
    await engineRef.current?.session.close();
  };

  const openApp = async () => {
    await closeSession();

    try {
      const globalQlik = await openSession();

      engineRef.current = await globalQlik.openDoc(appId);
      setTemporaryEngine(engineRef.current);
    } catch (err) {
      console.warn('Captured Error', err);
      if (!isRawQlikError(err)) {
        setEnigmaError(new QlikError('Failed to create a session', 'SESSION_ERROR'));
        return;
      }

      if (err.code === schema.enums.LocalizedErrorCode.LOCERR_GENERIC_ACCESS_DENIED) {
        setEnigmaError(new QlikError(err.message, 'ACCESS_DENIED'));
      } else {
        setEnigmaError(new QlikError(err.message, 'APP_OPENING_ERROR'));
      }
    }
  };

  const debouncedOpenApp = useDebounceCallback(openApp, APP_OPEN_DEBOUNCE_TIME_MS);

  useEffect(() => {
    debouncedOpenApp();

    return () => {
      setTemporaryEngine(null);
    };
  }, [connectionConfig, appId]);

  useEffect(() => {
    return () => {
      engineRef.current?.session.close().then(() => {
        setTemporaryEngine(null);
      });
    };
  }, []);

  useEffect(() => {
    setEngine(temporaryEngine);
  }, [temporaryEngine]);

  return { enigma: engine, enigmaError };
};
