import { QueryKeys } from '@common/query';
import { Services, ContractFiltersKeys } from '@models/service';
import { useQuery } from '@tanstack/react-query';
import { fetchServiceDetails } from '../api/service';
import { QueryOptions } from 'src/types/reactQuery';
import { useTranslation } from 'react-i18next';
import { shouldRetry } from '@utils/data';

const serviceDetailsQuery = (
  locationCode: string,
  selectedFiltersKeys: ContractFiltersKeys,
  language: string,
): QueryOptions<Services> => ({
  queryKey: [QueryKeys.servicesDetails, locationCode, selectedFiltersKeys, language],
  queryFn: () => fetchServiceDetails(locationCode, selectedFiltersKeys, language),
  enabled: !!locationCode,
  retry: shouldRetry,
});

export const useServiceDetails = (locationCode: string, selectedFiltersKeys: ContractFiltersKeys) => {
  const { i18n } = useTranslation();
  return useQuery<Services, Error>(serviceDetailsQuery(locationCode, selectedFiltersKeys, i18n.language));
};
