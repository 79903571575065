import { colors, ContentText, ContentTextProps, fontWeights, NotificationProps, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const CTAText = styled(ContentText)`
  cursor: pointer;
  margin-left: ${spacing.xxxxs};
  text-decoration: underline;
  transition: filter 0.2s;

  &:hover {
    filter: drop-shadow(0 0 2px ${colors.inkGrey});
  }
`;

export const headingTextProps: ContentTextProps = {
  display: 'flex',
  alignItems: 'center',
  fontWeight: fontWeights.regular,
};

export const notificationProps: NotificationProps = {
  borderRadius: 'none',
  paddingLeft: spacing.xxxs,
  paddingRight: spacing.xxxs,
};
