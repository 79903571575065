import { useUsersProfilesFilters } from '@data/hooks/useUsersProfilesFilters';
import { useResizeObserver } from '@hooks/useResizeObserver';
import { UserProfile } from '@models/usersManagement';
import { FC, useCallback, useRef, useState } from 'react';
import { PageHeader } from './components/PageHeader/PageHeader';
import { Container } from './components/styles';
import { UserProfilePanel } from './components/UserProfilePanel/UserProfilePanel';
import { UsersProfilesTableWithPagination } from './components/UsersProfilesTableWithPagination/UsersProfilesTableWithPagination';
import { COMPANY_FILTER_SELECTIONS_LIMIT } from './config';

export const UsersManagement: FC = () => {
  const pageHeaderContainerRef = useRef<HTMLDivElement>(null);

  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserProfile | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  const { data: filtersResponse } = useUsersProfilesFilters();

  const { containerHeight: pageHeaderHeight } = useResizeObserver(pageHeaderContainerRef);

  const onTableRawClick = useCallback((user: UserProfile) => setSelectedUser(user), []);
  const resetUser = useCallback(() => setSelectedUser(null), []);

  const setLimitedSelectedCompanies = useCallback((companies: string[]) => {
    if (companies.length <= COMPANY_FILTER_SELECTIONS_LIMIT) {
      setSelectedCompanies(companies);
    }
  }, []);

  return (
    <Container>
      <PageHeader
        ref={pageHeaderContainerRef}
        companies={filtersResponse?.data ?? []}
        selectedCompanies={selectedCompanies}
        setSelectedCompanies={setLimitedSelectedCompanies}
        searchQuery={searchQuery}
        onSearch={setSearchQuery}
        companiesSelectionLimit={COMPANY_FILTER_SELECTIONS_LIMIT}
      />
      <UsersProfilesTableWithPagination
        search={searchQuery}
        companies={selectedCompanies}
        pageHeaderHeight={pageHeaderHeight}
        onRowClick={onTableRawClick}
        selectedUserId={selectedUser?.uuid}
      />

      <UserProfilePanel selectedUser={selectedUser} open={!!selectedUser} onClose={resetUser} />
    </Container>
  );
};
