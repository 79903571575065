import { useCallback, useEffect, useState } from 'react';
import { QlikApp, QlikError, QlikConnectionConfig, QlikRootApi, isRawQlikError } from '../../types';
import { loadCapabilityApis } from './capabilityApis';
import { loadQlikRootApi } from './qlikRootApi';
import { appendSlash, toQlikError } from '@utils/qlik';
import { useDebounceCallback } from 'usehooks-ts';

const APIS_FETCHING_DEBOUNCE_TIME_MS = 500;

export const useCapability = (config: QlikConnectionConfig, appId: string) => {
  const [app, setApp] = useState<QlikApp | null>(null);
  const [temporaryApp, setTemporaryApp] = useState<QlikApp | null>(null);

  const [appError, setAppError] = useState<QlikError | null>(null);

  const [qlik, setQlik] = useState<QlikRootApi | null>(null);

  const onQlikApiError = useCallback((error: unknown) => {
    if (isRawQlikError(error)) {
      setAppError(toQlikError(error));
    } else {
      setAppError(new QlikError('Failed to open the app', 'APP_OPENING_ERROR'));
    }

    console.warn(error);
  }, []);

  const fetchApis = async () => {
    try {
      await loadCapabilityApis(config);
      const qlikApi = await loadQlikRootApi(config, onQlikApiError);
      setQlik(qlikApi);
    } catch (e) {
      setAppError(new QlikError('Failed to fetch apis', 'APIS_FETCHING_ERROR'));
    }
  };

  const debouncedFetchApis = useDebounceCallback(fetchApis, APIS_FETCHING_DEBOUNCE_TIME_MS);

  useEffect(() => {
    debouncedFetchApis();
  }, [config]);

  useEffect(() => {
    if (!qlik || app?.id === appId) return;
    setApp(null);

    const qlikApp = qlik.openApp(appId, {
      ...config,
      prefix: appendSlash(config.prefix),
    });

    setTemporaryApp(qlikApp);

    return () => {
      qlikApp.close();
    };
  }, [qlik, appId]);

  useEffect(() => {
    setApp(temporaryApp);
  }, [temporaryApp]);

  return { app, appError };
};
