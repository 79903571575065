import { fallbackLang, isSupportedLanguage } from '@common/languages';
import { Namespace } from '@config/i18n';
import { getWasteQtyReportApp } from '@config/qlik';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QlikWrapper } from '@components/qlik';
import { Report } from './report';
import { Notification, useNotification } from '@components/Notification';
import { CenteredLoader } from '@components/layout/styles';
import { ExportDataHandler } from '../types';

export const WasteQuantityReport: FC = () => {
  const { t, i18n } = useTranslation<Namespace[]>(['errors', 'reports']);
  const [errorMessage, setErrorMessage] = useState(t('errors:dataExport'));
  const { displayNotification, setDisplayErrorNotification, closeNotification } = useNotification();

  const reportApp = useMemo(() => {
    const currentLanguage = isSupportedLanguage(i18n.language) ? i18n.language : fallbackLang;

    return getWasteQtyReportApp(currentLanguage);
  }, [i18n.language]);

  const exportDataHandler = useMemo<ExportDataHandler>(
    () => ({
      objectId: reportApp.objectIds.visualization.table,
      onError: () => {
        setErrorMessage(t('errors:dataExport'));
        setDisplayErrorNotification();
      },
      onEmptyData: () => {
        setDisplayErrorNotification();
        setErrorMessage(t('errors:reports.emptyVisData.other'));
      },
    }),
    [setDisplayErrorNotification, reportApp, i18n.language],
  );

  return (
    <>
      <QlikWrapper connectionConfig={reportApp.connectionConfig} appId={reportApp.appId} fallback={<CenteredLoader />}>
        <Report
          defaultSelections={reportApp.defaultSelections}
          exportDataHandler={exportDataHandler}
          objectIds={reportApp.objectIds}
          mandatorySelections={reportApp.mandatorySelections}
        />
      </QlikWrapper>

      <Notification
        type="error"
        content={errorMessage}
        opened={displayNotification === 'error'}
        onClose={closeNotification}
        maxWidth="30rem"
      />
    </>
  );
};
