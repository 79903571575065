import { QueryKeys } from '@common/query';
import { Service, ContractFiltersKeys } from '@models/service';
import { UserAccess } from '@models/user';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';
import { getUserAccessInfo, isUserAccessOfInternal } from '@utils/user';
import { useTranslation } from 'react-i18next';
import { fetchServices } from '../api/service';
import { QueryOptions } from 'src/types/reactQuery';

const servicesQuery = (
  selectedFiltersKeys: ContractFiltersKeys,
  userAccess: UserAccess,
  language: string,
): QueryOptions<Service> => ({
  queryKey: isUserAccessOfInternal(userAccess)
    ? [QueryKeys.servicesAdmin, language, selectedFiltersKeys, userAccess.selectedBusinessPartners.map(bp => bp.id).join(',')]
    : [QueryKeys.services, language, selectedFiltersKeys],

  queryFn: () => fetchServices(selectedFiltersKeys, userAccess, language),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useServices = (selectedFiltersKeys: ContractFiltersKeys) => {
  const { i18n } = useTranslation();

  return useQuery<Service, Error>(servicesQuery(selectedFiltersKeys, getUserAccessInfo(), i18n.language));
};
