import { QueryOptions } from 'src/types/reactQuery';
import { QueryKeys } from '@common/query';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';
import { Contact } from '@models/contact';
import { fetchContactsByBusinessPartner } from '@data/api/user';

const contactsByBusinessPartnerQuery = (businessPartnerCode?: string): QueryOptions<Contact[]> => ({
  queryKey: [QueryKeys.contactsByBusinessPartner, businessPartnerCode],
  enabled: !!businessPartnerCode,
  queryFn: () => fetchContactsByBusinessPartner(businessPartnerCode || ''),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useContactsByBusinessPartner = (businessPartnerCode?: string) =>
  useQuery<Contact[], Error>(contactsByBusinessPartnerQuery(businessPartnerCode));
