import { QueryKeys } from '@common/query';
import { sessionKeys, StorageUtils } from '@common/storage';
import { setUpConnection } from '@data/api/qlik';
import { useQuery } from '@tanstack/react-query';
import { QueryOptions } from 'src/types/reactQuery';
import { shouldRetry } from '@utils/data';
import { QlikConnectionConfig } from '@components/qlik';

const qlikSessionQuery = (config: QlikConnectionConfig): QueryOptions<boolean> => ({
  queryKey: [QueryKeys.qlikAuth],
  queryFn: () => setUpConnection(config),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
  enabled: StorageUtils.hasItem(sessionKeys.idToken),
});

export const useQlikSession = (config: QlikConnectionConfig) => useQuery<boolean, Error>(qlikSessionQuery(config));
