import { Co2ReportObjects } from '@config/qlik';
import { FC } from 'react';
import { Section, SectionHorizontalSubContainer } from './styles';
import { QlikObject } from '@components/qlik';

interface EmissionInsightsProps {
  objectIds: Co2ReportObjects;
}

const CHARTS_PADDING = '8px';
const PIE_CHARTS_HEIGHT = '280px';
const BAR_CHARTS_HEIGHT = '344px';

export const EmissionInsights: FC<EmissionInsightsProps> = ({ objectIds }) => (
  <Section data-testid="emission-insights">
    <SectionHorizontalSubContainer>
      <QlikObject
        dataTestId="pie-chart-co2e-emissions-waste-desc"
        id={objectIds.charts.co2eEmissions}
        padding={CHARTS_PADDING}
        height={PIE_CHARTS_HEIGHT}
      />
      <QlikObject
        dataTestId="pie-chart-co2e-emissions-avoided"
        id={objectIds.charts.co2eEmissionsAvoided}
        padding={CHARTS_PADDING}
        height={PIE_CHARTS_HEIGHT}
      />
      <QlikObject
        dataTestId="pie-chart-emissions-by-logistics-and-treatment"
        id={objectIds.charts.emissionsByLogisticsAndTreatment}
        padding={CHARTS_PADDING}
        height={PIE_CHARTS_HEIGHT}
      />
    </SectionHorizontalSubContainer>

    <SectionHorizontalSubContainer>
      <QlikObject
        dataTestId="bar-chart-total-waste-amount"
        id={objectIds.charts.totalWasteAmount}
        padding={CHARTS_PADDING}
        height={BAR_CHARTS_HEIGHT}
      />

      <QlikObject
        dataTestId="bar-chart-total-emission"
        id={objectIds.charts.totalEmissions}
        padding={CHARTS_PADDING}
        height={BAR_CHARTS_HEIGHT}
      />
    </SectionHorizontalSubContainer>
  </Section>
);
