import { useUserInfo } from '@data/hooks/useUserInfo';
import { Box, Col, Grid, Row, colors } from '@fortum/elemental-ui';
import { BusinessPartner } from '@models/user';
import { CharactersLayout } from '@routes/customer/components/CharactersLayout';
import { ListLayout } from '@routes/customer/components/ListLayout';
import { SelectedLayout } from '@routes/customer/components/SelectedLayout';
import { CharacterChips } from 'src/types/filters';
import { getSelectedBusinessPartners } from '@utils/user';
import _, { isNaN, sortBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export const SelectCustomer = () => {
  const { data: userInfo } = useUserInfo();
  const [active, setActive] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [businessPartners, setBusinessPartners] = useState<BusinessPartner[]>([]);
  const [selectedBPs, setSelectedBPs] = useState<BusinessPartner[]>([]);

  const groupedCompanies = useMemo(() => {
    return _(businessPartners)
      .groupBy(o => o.name[0].toUpperCase())
      .map((contacts, letter) => ({
        letter,
        firstContract: contacts[0],
      }))
      .value()
      .reduce<Record<string, BusinessPartner>>((ac, c) => {
        ac[c.letter] = c.firstContract;
        return ac;
      }, {});
  }, [userInfo, businessPartners]);

  useEffect(() => {
    const bp =
      userInfo &&
      userInfo.businessPartners.map(bp => ({
        id: bp.id.trimStart(),
        name: bp.name.trimStart(),
      }));

    const sortedBp = sortBy(bp, [o => o.name.toLocaleLowerCase()]) || [];

    if (searchValue !== '' && active.length === 0) {
      setBusinessPartners(sortedBp.filter(textSearch));
    } else if (active.length > 0 && searchValue === '') {
      setBusinessPartners(sortedBp.filter(characterSearch));
    } else if (active.length > 0 && searchValue !== '') {
      setBusinessPartners(sortedBp.filter(characterSearch).filter(textSearch));
    } else {
      setBusinessPartners(sortedBp);
    }
  }, [userInfo, selectedBPs, searchValue, active]);

  const characterSearch = (bpId: BusinessPartner) => {
    const filterNumber = active.includes('numbers');
    const activeLetters = active.filter(a => a !== 'numbers');
    return (
      activeLetters.some(letter => bpId.name.startsWith(letter)) ||
      (filterNumber && characterChips.numbers.some(asd => bpId.name.startsWith(asd.value)))
    );
  };
  const textSearch = (bp: BusinessPartner) => bp.name.toLowerCase().includes(searchValue.toLowerCase());

  const characterChips: CharacterChips = useMemo(() => {
    const grouped = _(userInfo && userInfo.businessPartners)
      .groupBy(o => o.name.trimStart()[0].toUpperCase())
      .map((_, key) => ({ label: key, value: key }))
      .value();

    const data = sortBy(
      grouped.filter(e => isNaN(Number(e.value))),
      'value',
    );

    const numbers = sortBy(
      grouped.filter(e => !isNaN(Number(e.value))),
      'value',
    );
    data.unshift({
      label: `${numbers[0].value}-${numbers[numbers.length - 1].value}`,
      value: 'numbers',
    });

    return { letters: data, numbers };
  }, [userInfo]);

  useEffect(() => {
    const data = getSelectedBusinessPartners();

    setSelectedBPs([...selectedBPs, ...data]);
  }, []);

  const selectedState = (id: string, name: string) => {
    const isSelected = businessPartners.find(bp => bp.id === id && bp.name === name);
    if (isSelected) {
      setSelectedBPs([...selectedBPs, isSelected]);
    }
  };

  const deselect = (id: string, name: string) => {
    setSelectedBPs(selectedBPs.filter(bp => !(bp.id === id && bp.name === name)));
  };

  // if we didn't get business partners from BE we treat it as an error
  const hasError = userInfo?.businessPartners.length === 0;

  return (
    <Box display="flex" flex="1" flexDirection="column" backgroundColor={colors.snowWhite}>
      <Grid backgroundColor={colors.snowWhite}>
        <Row>
          <Col xxs={6}>
            <Box display="flex" flex={1} flexDirection="column" backgroundColor={colors.snowWhite}>
              <CharactersLayout characterChips={characterChips} active={active} setActive={setActive} />
              <ListLayout
                hasError={hasError}
                businessPartners={businessPartners}
                groupedBP={groupedCompanies}
                search={setSearchValue}
                searchValue={searchValue}
                selectedBps={selectedBPs}
                deselect={bp => deselect(bp.id, bp.name)}
                select={bp => selectedState(bp.id, bp.name)}
              />
            </Box>
          </Col>
          <Col xxs={1} />
          <Col xxs={5}>
            <SelectedLayout
              selectedBPs={selectedBPs}
              deselect={bp => deselect(bp.id, bp.name)}
              clearSelection={() => setSelectedBPs([])}
            />
          </Col>
        </Row>
      </Grid>
    </Box>
  );
};
