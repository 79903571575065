import { QlikConnectionConfig } from '@components/qlik/types';
import schema from 'enigma.js/schemas/12.170.2.json';

const MAX_RETRIES = 3;

export const responseInterceptors: enigmaJS.IResponseInterceptors[] = [
  {
    onRejected: (_sessionReference, request, error) => {
      console.warn('Captured Request: Rejected', `Error Code: ${error.code} : ${error}`);
      if (error.code === schema.enums.LocalizedErrorCode.LOCERR_GENERIC_ABORTED) {
        request.tries = (request.tries || 0) + 1;
        console.warn(`Captured Request: Retry #${request.tries}`);
        if (request.tries <= MAX_RETRIES) {
          return request.retry();
        }
      }

      if (error.code === -1) {
        console.log('Connection closed');
        return;
      }

      return Promise.reject(error);
    },
  },
];

const getWsProtocol = (isSecure: boolean) => (isSecure ? 'wss' : 'ws');

export const buildWebsocketUrl = ({ isSecure, host, port, prefix }: QlikConnectionConfig, appId: string) => {
  let url = '';

  const protocol = getWsProtocol(isSecure);

  url = url.concat(protocol, '://', host);

  if (port) {
    url = url.concat(':', port.toString());
  }

  if (prefix) {
    url = url.concat('/', prefix);
  }

  url = url.concat('/', 'app', '/', appId);

  return url;
};
