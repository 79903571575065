import { logEvent } from '@config/azureInsights';
import i18n, { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import { Col, IconBin, IconDownloadFromCloud, Row, RowProps } from '@fortum/elemental-ui';
import { hasAtLeastOneReportPermission, isCo2ReportToggleOn } from '@utils/featureToggles';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OrdersTile } from './OrdersTile';
import { ReportsTile } from './ReportsTile';
import { columnStyles, firstRowColumnStyles, threeColumnsInRowStyles, reportsColumnStyles } from './styles';
import { Tile, TileLink } from './Tile';
import { useUserInfo } from '@data/hooks/useUserInfo';
import { compact } from 'lodash';

export interface QuickAccessSectionProps {
  marginBottom: RowProps['mb'];
}

export const QuickAccessSection: FC<QuickAccessSectionProps> = ({ marginBottom }) => {
  const { t } = useTranslation<Namespace[]>(['reports', 'home']);
  const { data: user } = useUserInfo();

  if (!user) return null;

  const shouldDisplayCo2Report = isCo2ReportToggleOn(user);
  const shouldDisplayReportsTile = hasAtLeastOneReportPermission(user);

  const tilesAmount = useMemo<number>(
    () => 2 + compact([shouldDisplayCo2Report, shouldDisplayReportsTile]).length,
    [shouldDisplayCo2Report, shouldDisplayReportsTile],
  );

  const [wasteServiceLinks, co2ReportLinks] = useMemo<[TileLink[], TileLink[]]>(
    () => [
      [
        {
          id: 'services-forward-link',
          description: t('home:quickAccess.wasteServices.linkDesc'),
          path: paths.services,
          onClick: () => logEvent('homeQuickAccessMyServicesServiceViewClicked', null),
        },
      ],
      [
        {
          id: 'co2-report-forward-link',
          description: t('reports:co2.navigation'),
          path: paths.co2Report,
          onClick: () => logEvent('homeQuickAccessReportsCO2DetailsClicked', null),
        },
      ],
    ],
    [i18n.language],
  );

  return tilesAmount === 3 ? (
    <>
      <Row mb={marginBottom}>
        <Col {...threeColumnsInRowStyles}>
          <OrdersTile linksDirection="vertical" />
        </Col>

        <Col {...threeColumnsInRowStyles}>
          <Tile
            icon={<IconBin />}
            header={t('home:quickAccess.wasteServices.header')}
            description={t('home:quickAccess.wasteServices.desc')}
            links={wasteServiceLinks}
            linksDirection="vertical"
          />
        </Col>

        {shouldDisplayReportsTile && (
          <Col {...threeColumnsInRowStyles}>
            <ReportsTile linksDirection="vertical" user={user} />
          </Col>
        )}

        {shouldDisplayCo2Report && (
          <Col {...threeColumnsInRowStyles}>
            <Tile
              icon={<IconDownloadFromCloud />}
              header={t('home:quickAccess.sustainability.header')}
              description={t('home:quickAccess.sustainability.desc')}
              links={co2ReportLinks}
            />
          </Col>
        )}
      </Row>
    </>
  ) : (
    <>
      <Row mb={tilesAmount === 2 ? marginBottom : undefined}>
        <Col {...(tilesAmount === 2 ? columnStyles : firstRowColumnStyles)}>
          <OrdersTile />
        </Col>

        <Col {...(tilesAmount === 2 ? columnStyles : firstRowColumnStyles)}>
          <Tile
            icon={<IconBin />}
            header={t('home:quickAccess.wasteServices.header')}
            description={t('home:quickAccess.wasteServices.desc')}
            links={wasteServiceLinks}
          />
        </Col>
      </Row>

      {tilesAmount === 4 && (
        <Row mb={marginBottom}>
          <Col {...reportsColumnStyles}>
            <ReportsTile user={user} />
          </Col>

          <Col {...columnStyles}>
            <Tile
              icon={<IconDownloadFromCloud />}
              header={t('home:quickAccess.sustainability.header')}
              description={t('home:quickAccess.sustainability.desc')}
              links={co2ReportLinks}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
