import { QueryOptions } from 'src/types/reactQuery';
import { QueryKeys } from '@common/query';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';
import { UsersProfilesResponse } from '@models/usersManagement';
import { fetchUsersProfiles } from '@data/api/usersManagement';
import { UsersProfilesRequestParams } from 'src/types/usersManagement';

const usersProfilesQuery = (params: UsersProfilesRequestParams): QueryOptions<UsersProfilesResponse> => ({
  queryKey: [QueryKeys.usersProfiles, params],
  queryFn: () => fetchUsersProfiles(params),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useUsersProfiles = (params: UsersProfilesRequestParams) =>
  useQuery<UsersProfilesResponse, Error>(usersProfilesQuery(params));
