import { defaultSelectionsSchema } from '../schemas';
import { Co2ReportObjects, Co2ReportAppConfig, Co2ReportConfig } from './types';
import Ajv, { JSONSchemaType } from 'ajv';

const objectsSchema: JSONSchemaType<Co2ReportObjects> = {
  $id: 'objects-schema',
  type: 'object',
  properties: {
    filters: {
      type: 'object',
      properties: {
        companyName: { type: 'string' },
        companySiteCity: { type: 'string' },
        executionYear: { type: 'string' },
        executionMonth: { type: 'string' },
        hazardousNonHazardous: { type: 'string' },
        transportEmission: { type: 'string' },
      },
      required: [
        'companyName',
        'companySiteCity',
        'executionMonth',
        'executionYear',
        'hazardousNonHazardous',
        'transportEmission',
      ],
    },

    kpis: {
      type: 'object',
      properties: {
        co2eEmissions: { type: 'string' },
        avoidedCo2eEmissions: { type: 'string' },
        netCo2eEmissions: { type: 'string' },
        largestCo2eWasteProducing: { type: 'string' },
      },
      required: ['co2eEmissions', 'avoidedCo2eEmissions', 'netCo2eEmissions', 'largestCo2eWasteProducing'],
    },

    charts: {
      type: 'object',
      properties: {
        totalWasteAmount: { type: 'string' },
        totalEmissions: { type: 'string' },
        top3NetCo2eProducingWaste: { type: 'string' },
        emissionsByLogisticsAndTreatment: { type: 'string' },
        co2eEmissions: { type: 'string' },
        co2eEmissionsAvoided: { type: 'string' },
        emissionsByWasteTreatmentMethodCo2: { type: 'string' },
      },
      required: [
        'totalWasteAmount',
        'totalEmissions',
        'top3NetCo2eProducingWaste',
        'emissionsByLogisticsAndTreatment',
        'co2eEmissions',
        'co2eEmissionsAvoided',
        'emissionsByWasteTreatmentMethodCo2',
      ],
    },

    visualization: {
      type: 'object',
      properties: {
        table: { type: 'string' },
        dimensions: {
          type: 'object',
          properties: { companyInfo: { type: 'string' }, wasteDetails: { type: 'string' } },
          required: ['companyInfo', 'wasteDetails'],
        },
      },
      required: ['table', 'dimensions'],
    },
  },
  required: ['filters', 'kpis', 'charts', 'visualization'],
};

const appConfigSchema: JSONSchemaType<Co2ReportAppConfig> = {
  $id: 'app-config-schema',
  type: 'object',
  properties: {
    appId: { type: 'string' },
    objectIds: { type: 'object', $ref: 'objects-schema' },
    companyNameSelectionFieldName: { type: 'string' },
    defaultSelections: { type: 'array', items: defaultSelectionsSchema },
  },
  required: ['appId', 'companyNameSelectionFieldName', 'defaultSelections'],
};

const Co2ReportConfigSchema: JSONSchemaType<Co2ReportConfig> = {
  type: 'object',
  properties: {
    en: {
      type: 'object',
      $ref: 'app-config-schema',
      required: ['objectIds'],
    },
    fi: { type: 'object', $ref: 'app-config-schema' },
    sv: { type: 'object', $ref: 'app-config-schema' },
  },
  required: ['en'],
};

export const co2ReportConfigSchemaValidator = () =>
  new Ajv().addSchema([defaultSelectionsSchema, objectsSchema, appConfigSchema]).compile(Co2ReportConfigSchema);
