import { HorizontalContainer, VerticalContainer } from '@components/styles';
import { ButtonProps, Checkbox, ContentTextProps, fontSizes, fontWeights, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const Container = styled(VerticalContainer)`
  gap: ${spacing.xs};
  flex: 1;
`;
export const SectionContainer = styled(VerticalContainer)`
  gap: ${spacing.xxxs};
`;

export const sectionHeaderStyles: ContentTextProps = {
  fontSize: fontSizes.s,
  fontWeight: fontWeights.medium,
};

export const ButtonsContainer = styled(HorizontalContainer)`
  justify-content: flex-end;
  margin-top: auto;
  padding-top: ${spacing.xs};

  gap: ${spacing.xxs};
`;

export const primaryButtonStyles: ButtonProps = {
  size: 'm',
};

export const discardButtonStyles: ButtonProps = {
  ...primaryButtonStyles,
  status: 'plain',
};

export const backButtonStyles: ButtonProps = {
  ...primaryButtonStyles,
  status: 'secondary',
};

export const smallTextStyles: ContentTextProps = {
  fontSize: fontSizes.s,
};

export const agreementInfoInPreviewStyles: ContentTextProps = {
  ...smallTextStyles,
  mt: spacing.xxxs,
};

export const StyledCheckbox = styled(Checkbox)`
  label {
    font-size: ${fontSizes.m.default};
  }
`;
