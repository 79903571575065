import { QueryKeys } from '@common/query';
import { fetchOrders } from '@data/api/order';
import { OrdersSelectedFiltersWithSearch } from '@models/filters';
import { OrdersOverviewResponse } from '@models/orders';
import { UserAccess } from '@models/user';
import { NUMBER_OF_PAGE_ELEMENTS } from '@routes/orders/components/config';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';
import { getUserAccessInfo, isUserAccessOfInternal } from '@utils/user';
import { useTranslation } from 'react-i18next';
import { QueryOptions } from 'src/types/reactQuery';

export interface OrdersRequestParams {
  activePage: number;
  sortBy: string;
  filters: OrdersSelectedFiltersWithSearch;
  numberOfPageElements?: number;
}

const ordersQuery = (
  userAccess: UserAccess,
  activePage: number,
  numberOfPageElements: number,
  sortBy: string,
  filters: OrdersSelectedFiltersWithSearch,
  language: string,
): QueryOptions<OrdersOverviewResponse> => ({
  queryKey: isUserAccessOfInternal(userAccess)
    ? [
        QueryKeys.ordersAdmin,
        userAccess.selectedBusinessPartners.map(bp => bp.id).join(','),
        activePage,
        filters,
        numberOfPageElements,
        sortBy,
        language,
      ]
    : [QueryKeys.orders, activePage, filters, numberOfPageElements, sortBy, language],

  queryFn: () => fetchOrders(userAccess, filters, activePage, numberOfPageElements, sortBy, language),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useOrders = ({ activePage, sortBy, filters, numberOfPageElements }: OrdersRequestParams) => {
  const { i18n } = useTranslation();

  return useQuery<OrdersOverviewResponse, Error>(
    ordersQuery(getUserAccessInfo(), activePage, numberOfPageElements || NUMBER_OF_PAGE_ELEMENTS, sortBy, filters, i18n.language),
  );
};
