import { useCapability, useEnigma, QlikConnectionConfig } from '@components/qlik';
import { QlikAppContextProvider } from '@components/qlik/QlikAppProvider';
import { QlikEngineContextProvider } from '@components/qlik/QlikEngineProvider';
import { Loader } from '@fortum/elemental-ui';
import { FC, ReactNode } from 'react';

interface QlikWrapperProps {
  connectionConfig: QlikConnectionConfig;
  appId: string;
  children: ReactNode;
  fallback?: ReactNode;
}

export const QlikWrapper: FC<QlikWrapperProps> = ({ children, connectionConfig, appId, fallback }) => {
  const { enigmaError, enigma } = useEnigma(connectionConfig, appId);

  const { app, appError } = useCapability(connectionConfig, appId);

  if (enigmaError) {
    throw enigmaError;
  }

  if (appError) {
    throw appError;
  }

  if (enigma && app) {
    return (
      <QlikEngineContextProvider enigma={enigma}>
        <QlikAppContextProvider app={app}>{children}</QlikAppContextProvider>
      </QlikEngineContextProvider>
    );
  }

  return fallback ? <>{fallback}</> : <Loader />;
};
