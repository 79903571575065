export const sessionKeys = {
  accessToken: 'access_token',
  codeVerifier: 'code_verifier',
  currentFEVersion: 'current_FE_version',
  idToken: 'id_token',
  logMetric: 'log_metric',
  redirectUri: 'redirect_uri',
  // it should store coma separated business partners selected in the Company Selector component
  selectedBusinessPartners: 'selected_BP',
  state: 'state',
  tokenRefreshAttempt: 'token_refresh_attempt',
};

const hasItem = (key: string): boolean => !!sessionStorage.getItem(key);

const putItem = (key: string, value: string): void => sessionStorage.setItem(key, value);

const getItem = (key: string): string | null => sessionStorage.getItem(key);

const getObject = <T = unknown>(key: string): T | null => {
  const item = sessionStorage.getItem(key);

  if (!item) return null;

  return JSON.parse(item) as T;
};

const removeItem = (key: string): void => sessionStorage.removeItem(key);

const popItem = (key: string): string | null => {
  const item = sessionStorage.getItem(key);

  sessionStorage.removeItem(key);

  return item;
};

export const StorageUtils = {
  hasItem,
  putItem,
  getItem,
  getObject,
  removeItem,
  popItem,
  sessionKeys,
};
