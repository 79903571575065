export interface QlikVisualization {
  show: (visualizationNode: HTMLElement, options: { noSelections: boolean; noInteraction: boolean }) => void;
  close: () => void;
  resize: () => void;
  qapp: {
    id: string;
  };
  exportData: (options: { format: string; state?: string; filename?: string }) => Promise<string>;
}

export interface QlikRootApi {
  openApp: (appId: string, config: QlikConnectionConfig) => QlikApp;
  resize: () => void;
}

interface CreateBookmarkExProperties {
  qProp: {
    qInfo: {
      qType: 'bookmark';
    };
    qMetaDef: {
      title: string;
      description?: string;
      isExtended: boolean;
    };
    qIncludeVariables: boolean;
  };
  qObjectIdsToPatch?: string[];
}

interface CreateBookmarkExReturnValue {
  qInfo: {
    id: string;
  };
}

export interface QlikApp {
  id: string;
  visualization: {
    get: (id: string) => Promise<QlikVisualization | null>;
  };
  bookmark: {
    create: (title: string, description: string) => Promise<{ id: string }>;
    apply: (id: string) => Promise<boolean>;
    remove: (id: string) => Promise<boolean>;
  };
  close: () => void;
  model: {
    engineApp: {
      clearAllSoftPatches: () => Promise<void>;
      createBookmarkEx: (props: CreateBookmarkExProperties) => Promise<CreateBookmarkExReturnValue>;
    };
  };
}

export interface QlikConnectionConfig {
  host: string;
  isSecure: boolean;
  port?: number;
  prefix: string;
  qsServerType: 'onPrem';
}

export type QLIK_ERRROR_TYPE =
  | 'APP_OPENING_ERROR'
  | 'APP_CLOSING_ERROR'
  | 'SESSION_ERROR'
  | 'ACCESS_DENIED'
  | 'SESSION_TIMED_OUT'
  | 'APIS_FETCHING_ERROR';

export class QlikError extends Error {
  constructor(
    message: string,
    private errorType: QLIK_ERRROR_TYPE,
  ) {
    super(message);
  }

  getErrorType = () => this.errorType;
}

export type QlikRawError = { code: number | undefined; message: string };

export const qlikRawErrorMessages = {
  proxySessionTimedOut: 'ProxyError.OnSessionTimedOut',
  proxySessionClosed: 'ProxyError.OnSessionClosed',
  noData: 'No data',
};

export const isRawQlikError = (error: unknown): error is QlikRawError =>
  typeof error === 'object' && !!error && 'message' in error;

export const isQlikError = (error: unknown): error is QlikError =>
  typeof error === 'object' && !!error && 'getErrorType' in error;
