import { getEnvProperty } from '@utils/general';

const reactAppApiUrl = getEnvProperty('REACT_APP_API_URL');

const baseUrlV1 = `${reactAppApiUrl}/rwportal-orders/v1`;
const baseUrlV3 = `${reactAppApiUrl}/rwportal-orders/v3`;

export const OrderService = {
  orderInfoAdmin: `${baseUrlV3}/orders/info`,
  orderInfo: `${baseUrlV3}/orders/info/own`,
  ordersFilters: `${baseUrlV3}/orders/filters/own`,
  ordersFiltersForAdmin: `${baseUrlV3}/orders/filters`,
  orderDetails: `${baseUrlV3}/orders`,
  ordersCreation: `${baseUrlV3}/orders/create`,
  orderNoChange: `${baseUrlV1}/backoffice/orderNo/update`,
  orderManagement: `${baseUrlV1}/backoffice/order`,
};
