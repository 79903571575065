import { Dispatch, FC, SetStateAction, memo, useCallback, useEffect, useRef, useState } from 'react';
import { INITIAL_PAGE_NUMBER, TOTAL_PAGES_ON_DATA_LOADING } from '@common/consts';
import { ContractsTable } from './ContractsTable';
import { StyledPagination, TableWithPaginationContainer, StyledErrorView, StyledFilteringResult } from './styles';
import { ContractFiltersKeys } from '@models/service';
import { UseContractsByLocationsParams, useContractsByLocations } from '@data/hooks/useContractsByLocations';
import { Namespace } from '@config/i18n/i18n.namespaces';
import { useTranslation } from 'react-i18next';
import { ContractByLocation } from '@models/contract';
import { createContractIdentifier } from '@utils/routes';
import { isEqual } from 'lodash';
import { PaginationSize, paginationHeights } from '@components/Pagination';
import { APPLY_ADDITIONAL_FILTERING_TO_SERVICES_SELECTOR_DATA } from '@routes/ordersCreation/config';
import { scrollToTop } from '@utils/general';

const NUMBER_OF_PAGE_ELEMENTS = 20;
const PAGINATION_SIZE: PaginationSize = 'm';
const PAGINATION_HEIGHT_PX = paginationHeights[PAGINATION_SIZE];
const TABLE_ID = 'contract-table';

interface ContractsByLocationsFilters extends ContractFiltersKeys {
  selectedLocationsCodes: string[];
  contractsFetchingEnabled: boolean;
  setSelectedContractIdentifier: Dispatch<SetStateAction<string | null>>;
  selectedContractIdentifier: string | null;
}

export const ContractTableWithPagination: FC<ContractsByLocationsFilters> = memo(
  ({
    selectedLocationsCodes,
    setSelectedContractIdentifier,
    selectedContractIdentifier,
    contractsFetchingEnabled,
    ...filters
  }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation<Namespace[]>(['services']);
    const [totalPaginationPages, setTotalPaginationPages] = useState<number>(TOTAL_PAGES_ON_DATA_LOADING);

    const [contractsRequestParams, setContractsRequestParams] = useState<UseContractsByLocationsParams>({
      numberOfPageElements: NUMBER_OF_PAGE_ELEMENTS,
      activePage: INITIAL_PAGE_NUMBER,
      filterForOrderCreation: APPLY_ADDITIONAL_FILTERING_TO_SERVICES_SELECTOR_DATA,
      enabled: contractsFetchingEnabled,
      locationCodes: selectedLocationsCodes,
      ...filters,
    });

    const { data: contractsResponse, isLoading, isPending, error } = useContractsByLocations(contractsRequestParams);

    useEffect(() => {
      const shouldRestoreActivePage = totalPaginationPages === TOTAL_PAGES_ON_DATA_LOADING;

      setContractsRequestParams(prev => ({
        ...prev,
        ...filters,
        locationCodes: selectedLocationsCodes,
        enabled: contractsFetchingEnabled,
        activePage: shouldRestoreActivePage ? prev.activePage : INITIAL_PAGE_NUMBER,
      }));

      setTotalPaginationPages(TOTAL_PAGES_ON_DATA_LOADING);
    }, [selectedLocationsCodes]);

    const setActivePage = useCallback((page: number) => {
      setContractsRequestParams(prev => ({ ...prev, activePage: page }));

      scrollToTop(containerRef.current?.querySelector(`#${TABLE_ID}`));
    }, []);

    useEffect(() => {
      if (contractsResponse) {
        setTotalPaginationPages(Math.ceil(contractsResponse.metadata.total / NUMBER_OF_PAGE_ELEMENTS));
      }
    }, [contractsResponse?.metadata.total]);

    useEffect(() => {
      if (!contractsResponse) return;

      const selectedContract = contractsResponse.response.data.find(
        contract => createContractIdentifier(contract.dataSource, contract.contractNo) === selectedContractIdentifier,
      );

      if (!selectedContract) {
        setSelectedContractIdentifier(null);
      }
    }, [contractsResponse, selectedContractIdentifier]);

    const onTableRowClick = useCallback(
      (row: ContractByLocation) =>
        setSelectedContractIdentifier(prev => {
          const identifier = createContractIdentifier(row.dataSource, row.contractNo);

          return prev === identifier ? null : identifier;
        }),
      [setSelectedContractIdentifier],
    );

    const shouldDisplayTable = isLoading || isPending || !!contractsResponse;
    const shouldHidePagination = totalPaginationPages <= 1;
    const paginationDisplayed = (!error && !!contractsResponse && contractsResponse.metadata.total > 0) || isLoading;

    return (
      <TableWithPaginationContainer ref={containerRef}>
        {!contractsFetchingEnabled ? (
          <StyledFilteringResult message={t('services:selectLocationOrFilter')} />
        ) : (
          <>
            {shouldDisplayTable ? (
              <ContractsTable
                id={TABLE_ID}
                isLoading={isLoading || isPending}
                contracts={contractsResponse?.response.data ?? []}
                paginationHeight={paginationDisplayed ? PAGINATION_HEIGHT_PX : '0px'}
                selectedContractIdentifier={selectedContractIdentifier}
                onRowClick={onTableRowClick}
              />
            ) : (
              <StyledErrorView />
            )}

            {paginationDisplayed && (
              <StyledPagination
                size={PAGINATION_SIZE}
                totalPages={totalPaginationPages}
                activePage={contractsRequestParams.activePage}
                setActivePage={setActivePage}
                hidePagination={shouldHidePagination}
              />
            )}
          </>
        )}
      </TableWithPaginationContainer>
    );
  },
  (prev, next) => isEqual(prev, next),
);

ContractTableWithPagination.displayName = 'ContractTableWithPagination';
