import { Co2ReportObjects } from '@config/qlik';
import { FC } from 'react';
import { QlikObject } from '@components/qlik';
import { SectionHorizontalSubContainer, Section } from './styles';

interface EmissionOverviewProps {
  objectIds: Co2ReportObjects;
}

const VISUALIZATION_PADDING = '8px';
const VISUALIZATION_HEIGHT = '150px';

export const EmissionOverview: FC<EmissionOverviewProps> = ({ objectIds }) => (
  <Section data-testid="emission-overview">
    <SectionHorizontalSubContainer>
      <QlikObject
        id={objectIds.kpis.co2eEmissions}
        dataTestId="kpi-co2e-emissions"
        padding={VISUALIZATION_PADDING}
        height={VISUALIZATION_HEIGHT}
      />
      <QlikObject
        padding={VISUALIZATION_PADDING}
        id={objectIds.kpis.avoidedCo2eEmissions}
        dataTestId="kpi-avoided-co2e-emissions"
        height={VISUALIZATION_HEIGHT}
      />

      <QlikObject
        id={objectIds.kpis.netCo2eEmissions}
        padding={VISUALIZATION_PADDING}
        dataTestId="kpi-net-co2e-emissions"
        height={VISUALIZATION_HEIGHT}
      />
    </SectionHorizontalSubContainer>

    <QlikObject
      padding={VISUALIZATION_PADDING}
      id={objectIds.charts.top3NetCo2eProducingWaste}
      dataTestId="kpi-largest-co2-waste-producing"
      height={VISUALIZATION_HEIGHT}
    />
  </Section>
);
