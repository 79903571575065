import { QueryOptions } from 'src/types/reactQuery';
import { QueryKeys } from '@common/query';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';
import { DetailedUserProfile } from '@models/usersManagement';
import { fetchUserProfile } from '@data/api/usersManagement';

const userProfileQuery = (userId: string | undefined): QueryOptions<DetailedUserProfile> => ({
  queryKey: [QueryKeys.userProfile, userId],
  enabled: !!userId,
  queryFn: () => fetchUserProfile(userId!),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useUsersProfile = (userId: string | undefined) => useQuery<DetailedUserProfile, Error>(userProfileQuery(userId));
