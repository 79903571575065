import { DetailedUserProfile, UsersProfilesFiltersResponse, UsersProfilesResponse } from '@models/usersManagement';
import { getData, postData } from '@utils/data';
import { Headers as HeadersConstants, MimeTypes } from 'http-constants-ts';
import { UsersProfilesRequestParams } from 'src/types/usersManagement';
import { UsersListRequestBody } from '@models/usersManagement';
import { urls } from './config';

const appJsonContentTypeHeader = new Map([[HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON]]);

export const fetchUserProfile = async (userId: string): Promise<DetailedUserProfile> => {
  const url = `${urls.usersList}/${userId}`;

  return getData<DetailedUserProfile>(url);
};

export const fetchUsersProfiles = async (params: UsersProfilesRequestParams): Promise<UsersProfilesResponse> => {
  const url = `${urls.usersList}?page=${params.activePage - 1}&size=${params.numberOfPageElements}`;

  const body: UsersListRequestBody = {
    filters: {
      userName: params.search || null,
      companies: params.companies.length > 0 ? params.companies : null,
    },
  };

  return postData<UsersProfilesResponse>(url, JSON.stringify(body), appJsonContentTypeHeader);
};

export const fetchUsersProfilesFilters = async (): Promise<UsersProfilesFiltersResponse> =>
  getData<UsersProfilesFiltersResponse>(urls.usersListFilters);
