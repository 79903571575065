import { TRIGGER_REQUEST_SEARCH_LENGTH_THRESHOLD } from '@common/consts';
import { logEvent } from '@config/azureInsights';
import { Namespace } from '@config/i18n';
import { ContractsFiltersContext } from '@contexts/ContractsFiltersContext';
import { useDebounceWithTemporaryValue } from '@hooks/useDebounceWithTemporaryValue';
import { Container, StyledSearch, StyledSelect } from '@routes/services/components/filters/style';
import { displayMultiselectAppliedValues, filterSelectItems, moveSelectedToStart } from '@utils/dataOperations';
import { shouldFiltersBeDisabled } from '@utils/servicesFilters';
import { FC, memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractsFiltersDisability } from 'src/types/services';

const FiltersComponent: FC = () => {
  const { t } = useTranslation<Namespace[]>(['services', 'errors']);

  const {
    handleSearchChange,
    handleWasteTypesChange,
    handleEquipmentTypesChange,
    queryResult,
    search,
    selectedEquipmentTypes,
    selectedWasteTypes,
    filtersKeysWithSearch: filtersKeys,
    filtersSelectItems,
    lastDataReloadCause,
  } = useContext(ContractsFiltersContext);

  const handleSearchValueChange = (searchText: string) => {
    const trimmedValue = searchText.trim();
    if (!searchText || trimmedValue.length > TRIGGER_REQUEST_SEARCH_LENGTH_THRESHOLD) {
      handleSearchChange(trimmedValue);

      trimmedValue && logEvent('myServicesSearchUsedDebounced', { searchedValue: trimmedValue });
    }
  };

  const { changeTemporaryValue, temporaryValue } = useDebounceWithTemporaryValue(search, handleSearchValueChange);

  const sortedWasteItems = useMemo(
    () => moveSelectedToStart(filtersSelectItems.wasteTypes, filtersKeys.wastes),
    [filtersKeys.wastes, filtersSelectItems.wasteTypes],
  );

  const sortedEquipmentItems = useMemo(
    () => moveSelectedToStart(filtersSelectItems.equipmentTypes, filtersKeys.equipments),
    [filtersSelectItems.equipmentTypes, filtersKeys.equipments],
  );

  const selectedWasteDisplayValue = useMemo<string | undefined>(() => {
    if (selectedWasteTypes.length > 0) {
      return displayMultiselectAppliedValues(selectedWasteTypes.map(wasteType => wasteType.value));
    }
  }, [selectedWasteTypes]);

  const selectedEquipmentDisplayValue = useMemo<string | undefined>(() => {
    if (selectedEquipmentTypes.length > 0) {
      return displayMultiselectAppliedValues(selectedEquipmentTypes.map(equipmentType => equipmentType.value));
    }
  }, [selectedEquipmentTypes]);

  const shouldBeDisabled = useMemo<ContractsFiltersDisability>(
    () => shouldFiltersBeDisabled(filtersSelectItems, queryResult.isLoading, queryResult.isError, lastDataReloadCause),
    [queryResult.isLoading, queryResult.isError, filtersSelectItems, lastDataReloadCause],
  );

  const handleWasteTypesSelectedItemsChange = (selectedItems: string[]) => {
    logEvent('myServicesFilterWasteTypeSelected', { selectedValue: selectedItems.join(',') });

    handleWasteTypesChange(selectedItems);
  };

  const handleEquipmentTypesSelectedItemsChange = (selectedItems: string[]) => {
    logEvent('myServicesFilterEquipmentTypeSelected', { selectedValue: selectedItems.join(',') });

    handleEquipmentTypesChange(selectedItems);
  };

  return (
    <Container id={'filters'}>
      <StyledSelect
        id="wasteTypeId"
        name="waste-types"
        width="21rem"
        placeholder={t('services:all')}
        variant="condensed"
        label={t('services:wasteType')}
        onSelectedItemsChange={handleWasteTypesSelectedItemsChange}
        items={sortedWasteItems}
        selected={filtersKeys.wastes}
        error={queryResult.isError}
        displayValue={selectedWasteDisplayValue}
        disabled={shouldBeDisabled.wasteTypes}
        errorMessage={t('errors:generalError.failedToFetch')}
        filterItems={filterSelectItems}
      />

      <StyledSelect
        id="equipmentTypeId"
        placeholder={t('services:all')}
        name="equipment-types"
        label={t('services:equipmentType')}
        width="21rem"
        variant="condensed"
        onSelectedItemsChange={handleEquipmentTypesSelectedItemsChange}
        selected={filtersKeys.equipments}
        disabled={shouldBeDisabled.equipmentTypes}
        displayValue={selectedEquipmentDisplayValue}
        items={sortedEquipmentItems}
        error={queryResult.isError}
        errorMessage={t('errors:generalError.failedToFetch')}
        filterItems={filterSelectItems}
      />

      <StyledSearch
        id="searchId"
        name="search"
        label={t('services:search')}
        width="18rem"
        value={temporaryValue}
        onChange={changeTemporaryValue}
        rightIconAriaLabel={t('services:clearSearch')}
        maxWidth="18rem"
        variant="condensed"
      />
    </Container>
  );
};

export const FiltersLayout = memo(FiltersComponent);
