import { QueryKeys } from '@common/query';
import i18n from '@config/i18n';
import { fetchOrderFilters } from '@data/api/order';
import { QueryOptions } from 'src/types/reactQuery';
import { OrderFilters, OrdersSelectedFiltersWithSearch } from '@models/filters';
import { UserAccess } from '@models/user';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';
import { getUserAccessInfo, isUserAccessOfInternal } from '@utils/user';

/**
 * In order to not overwrite MainFilters availableFilters with the temporary changes on the FilterPane, they have to be stored under different query keys
 * It is controlled using the @param temporaryState
 */

const orderFiltersQuery = (
  temporaryState: boolean,
  userAccess: UserAccess,
  precedence: { key: string; order: number }[],
  selectedFilters: OrdersSelectedFiltersWithSearch,
  language: string,
): QueryOptions<OrderFilters> => ({
  queryKey: isUserAccessOfInternal(userAccess)
    ? [
        QueryKeys.orderFiltersAdmin,
        userAccess.selectedBusinessPartners.map(bp => bp.id).join(','),
        `temporary=${temporaryState}`,
        selectedFilters,
        language,
        precedence,
      ]
    : [QueryKeys.orderFilters, `temporary=${temporaryState}`, selectedFilters, language, precedence],
  queryFn: () => fetchOrderFilters(userAccess, precedence, selectedFilters, language),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useOrderFilters = (
  temporaryState: boolean,
  precedence: { key: string; order: number }[],
  selectedFilters: OrdersSelectedFiltersWithSearch,
) =>
  useQuery<OrderFilters, Error>(
    orderFiltersQuery(temporaryState, getUserAccessInfo(), precedence, selectedFilters, i18n.language),
  );
