import { connectionSchema } from './schemas';
import { createSchemaErrorMessage } from '@utils/qlik';
import Ajv from 'ajv';
import { QlikConnectionConfig } from '@components/qlik';

export const getQlikConnectionConfig = (): QlikConnectionConfig => {
  if (!process.env.REACT_APP_QLIK_CONNECTION_CONFIG) {
    throw Error('Qlik connection config not found');
  }
  const config = JSON.parse(process.env.REACT_APP_QLIK_CONNECTION_CONFIG);

  const validator = new Ajv().compile(connectionSchema);

  if (!validator(config)) {
    throw Error('Qlik connection config does not match the schema: ' + createSchemaErrorMessage(validator.errors));
  }

  return config;
};
