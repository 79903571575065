import { getEnvProperty } from '@utils/general';

const reactAppApiUrl = getEnvProperty('REACT_APP_API_URL');
const baseUrl = `${reactAppApiUrl}/rwportal-users`;
const baseUrlV1 = `${baseUrl}/v1`;
const baseUrlV3 = `${baseUrl}/v3`;
const baseUrlV4 = `${baseUrl}/v4`;

export const UserService = {
  customerContactsByBusinessPartner: `${baseUrlV3}/contact/by-business-partner`,
  customerServiceContact: `${baseUrlV3}/contact/customer-service`,
  feVersion: `${baseUrl}/info`,
  passwordChange: `${baseUrlV4}/user/change-password`,
  userAgreement: `${baseUrlV4}/user/agreement`,
  userInfo: `${baseUrlV4}/user/details`,
  userLogOut: `${baseUrlV4}/user/logout`,
  usersList: `${baseUrlV1}/users`,
  usersListFilters: `${baseUrlV1}/users/companies`,
};
