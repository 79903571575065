import { QueryKeys } from '@common/query';
import { StorageUtils } from '@common/storage';
import { fetchServiceInfo } from '@data/api/user';
import { ServiceInfo } from '@models/common';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

const CHECK_INTERVAL_MS = 5 * 60 * 1000;

interface UseVersionCheck {
  isOutdated: boolean;
  triggerVersionUpdate: () => void;
}

export const useVersionCheck = (): UseVersionCheck => {
  const [isOutdated, setIsOutdated] = useState(false);

  const {
    isPending,
    isFetching,
    isError,
    data: { feVersion } = {} as ServiceInfo,
  } = useQuery<ServiceInfo>({
    queryKey: [QueryKeys.info],
    queryFn: fetchServiceInfo,
    refetchInterval: CHECK_INTERVAL_MS,
  });

  const triggerVersionUpdate = useCallback(() => {
    sessionStorage.removeItem(StorageUtils.sessionKeys.currentFEVersion);
    window.location.replace(window.location.pathname + window.location.search + window.location.hash);
  }, []);

  useEffect(() => {
    if (isPending || isFetching || isError || !feVersion) return;

    const currentFEVersion = sessionStorage.getItem(StorageUtils.sessionKeys.currentFEVersion);

    if (!currentFEVersion) sessionStorage.setItem(StorageUtils.sessionKeys.currentFEVersion, feVersion);
    else if (currentFEVersion !== feVersion) setIsOutdated(true);
  }, [feVersion, isPending, isFetching, isError]);

  return { isOutdated, triggerVersionUpdate };
};
