import { TRIGGER_REQUEST_SEARCH_LENGTH_THRESHOLD } from '@common/consts';
import { InputField } from '@components/InputField';
import i18n, { Namespace } from '@config/i18n';
import { IconSearch, SelectItem } from '@fortum/elemental-ui';
import { UserCompany } from '@models/usersManagement';
import { displayMultiselectAppliedValues, filterSelectItems, moveSelectedToStart } from '@utils/dataOperations';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiltersContainer, inputFieldStyles, LimitedMultiselect, multiselectStyles } from './styles';
import { useDebounceWithTemporaryValue } from '@hooks/useDebounceWithTemporaryValue';

interface FiltersProps {
  setSelectedCompanies: (companies: string[]) => void;
  selectedCompanies: string[];
  companies: UserCompany[];
  searchQuery: string;
  onSearch: (value: string) => void;
  companiesSelectionLimit: number;
}

export const Filters: FC<FiltersProps> = ({
  setSelectedCompanies,
  selectedCompanies,
  companies,
  searchQuery,
  onSearch,
  companiesSelectionLimit,
}) => {
  const { t } = useTranslation<Namespace[]>(['usersManagement', 'common']);

  const handleSearchValueChange = (searchText: string) => {
    if (!searchText || searchText.trim().length > TRIGGER_REQUEST_SEARCH_LENGTH_THRESHOLD) onSearch(searchText.trim());
  };

  const { changeTemporaryValue: setTempSearchQuery, temporaryValue: tempSearchQuery } = useDebounceWithTemporaryValue(
    searchQuery,
    handleSearchValueChange,
  );

  const sortedCompaniesItems = useMemo<SelectItem<string>[]>(() => {
    const items: SelectItem<string>[] = companies.map(({ name }) => ({ name, value: name }));

    return moveSelectedToStart(items, selectedCompanies);
  }, [companies, selectedCompanies]);

  const companiesSelectorDisplayValue = useMemo(() => displayMultiselectAppliedValues(selectedCompanies), [selectedCompanies]);

  const label = useMemo(() => {
    if (!selectedCompanies.length) return t('usersManagement:usersList.company.header');

    const limitLabel = `(${selectedCompanies.length} / ${companiesSelectionLimit})`;
    return `${t('usersManagement:usersList.company.header')} ${limitLabel}`;
  }, [selectedCompanies.length, i18n.language]);

  return (
    <FiltersContainer>
      <LimitedMultiselect
        $limitReached={selectedCompanies.length >= companiesSelectionLimit}
        id="companies-multiselect"
        name="companiesMultiselect"
        label={label}
        placeholder={t('common:all')}
        onSelectedItemsChange={setSelectedCompanies}
        selected={selectedCompanies}
        items={sortedCompaniesItems}
        displayValue={companiesSelectorDisplayValue}
        filterItems={filterSelectItems}
        {...multiselectStyles}
      />

      <InputField
        name="search-input-field"
        label={t('usersManagement:filters.search.label')}
        placeholder={t('common:search')}
        value={tempSearchQuery}
        onChange={setTempSearchQuery}
        icon={<IconSearch />}
        {...inputFieldStyles}
      />
    </FiltersContainer>
  );
};
