import { NotificationBanner } from '@components/NotificationBanner/NotificationBanner';
import { NotificationStatus } from '@fortum/elemental-ui';
import { translateIfExist } from '@utils/internationalization';
import { FC } from 'react';

type NotificationType = Extract<NotificationStatus, 'warning' | 'alert' | 'info'>;

const globalNotificationsKeys = ['globalNotificationInfo', 'globalNotificationWarning', 'globalNotificationError'] as const;
type GlobalNotificationKey = (typeof globalNotificationsKeys)[number];

const globalNotificationKeyToTypeMap: Record<GlobalNotificationKey, NotificationType> = {
  globalNotificationInfo: 'info',
  globalNotificationWarning: 'warning',
  globalNotificationError: 'alert',
};

export const GlobalBanner: FC = () => {
  const alertNotification = translateIfExist('globalNotificationError', 'common');
  const warningNotification = translateIfExist('globalNotificationWarning', 'common');
  const infoNotification = translateIfExist('globalNotificationInfo', 'common');

  const shouldShowInfoNotification = infoNotification && !(alertNotification && warningNotification);

  return (
    <>
      {alertNotification && (
        <NotificationBanner message={alertNotification} type={globalNotificationKeyToTypeMap.globalNotificationError} />
      )}
      {warningNotification && (
        <NotificationBanner
          message={warningNotification}
          type={globalNotificationKeyToTypeMap.globalNotificationWarning}
          canClose
        />
      )}
      {shouldShowInfoNotification && (
        <NotificationBanner message={infoNotification} type={globalNotificationKeyToTypeMap.globalNotificationInfo} canClose />
      )}
    </>
  );
};
